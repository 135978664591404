import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import ApproxAPIClient from '../../../ApproxAPIClient';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CreateProformaInvoiceDialog from './CreateProformaInvoiceDialog';

export default function ProformaInvoiceList({ handleResetProformaInvoiceSelect, handleProformaInvoiceSelect, selectedProformaInvoice, proformaInvoiceListRenderID, refreshProformaInvoiceList }) {
    const approxAPIClient = ApproxAPIClient();
    const [proformaInvoices, setProformaInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const [createProformaInvoiceIsOpen, setCreateProformaInvoiceIsOpen] = useState(false);

    const handleCreateProformaInvoiceClick = () => {
        setCreateProformaInvoiceIsOpen(true);
    };

    const handleCreateProformaInvoiceSuccess = () => {
        refreshProformaInvoiceList();
    }

    useEffect(() => {
        try {
            setIsLoading(true);

            approxAPIClient.fetchProformaInvoices().then((response) => {
                if (response.isSuccessful === true) {
                    setProformaInvoices(response.data);
                } else {
                    alert(response.error);
                }
            }).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error fetching ProformaInvoice list:', error);
        }
    }, [approxAPIClient, proformaInvoiceListRenderID])

    const handleProformaInvoiceClick = (proformaInvoice) => {
        handleProformaInvoiceSelect(proformaInvoice);
    }

    const handleProformaInvoiceDelete = async (proformaInvoiceID) => {
        setIsLoading(true);
        try {
            const input = {
                proformaInvoiceID: proformaInvoiceID
            }

            await approxAPIClient.deleteProformaInvoice(input).then((response) => {
                if (response.isSuccessful === true) {
                    const others = proformaInvoices.filter((x) => x.id !== proformaInvoiceID);
                    setProformaInvoices([...others]);
                    handleResetProformaInvoiceSelect();
                } else {
                    alert(response.error);
                }
            })
        } catch (error) {
            alert(error);
        }

        setIsLoading(false);
    }

    return (
        <Paper style={{ height: '100%' }}>
            <CreateProformaInvoiceDialog
                createProformaInvoiceIsOpen={createProformaInvoiceIsOpen}
                setCreateProformaInvoiceIsOpen={setCreateProformaInvoiceIsOpen}
                handleCreateProformaInvoiceSuccess={handleCreateProformaInvoiceSuccess}
            />
            <List
                component="nav"
                aria-label="project list"
                style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                            <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                Proforma Invoices
                            </Typography>
                            <Button variant="outlined" size="small" onClick={handleCreateProformaInvoiceClick}>Create</Button>
                        </Stack>
                    </ListSubheader>
                }
            >
                {!isLoading && proformaInvoices && proformaInvoices.length > 0 ? proformaInvoices.map(proformaInvoice =>
                    <div key={proformaInvoice.id}>
                        <ListItemButton
                            selected={proformaInvoice.id === selectedProformaInvoice?.id}
                            onClick={() => handleProformaInvoiceClick(proformaInvoice)}
                        >
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={proformaInvoice.companyName}
                                secondary={`${proformaInvoice.validFrom} - ${proformaInvoice.validTo}`}
                                primaryTypographyProps={{
                                    fontSize: 15,
                                    fontWeight: 'medium',
                                    lineHeight: '20px',
                                    mb: '2px',
                                    color: 'black'
                                }}
                            />
                            <IconButton edge="end" aria-label="delete" onClick={() => handleProformaInvoiceDelete(proformaInvoice.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemButton>
                    </div>
                ) : !isLoading && proformaInvoices.length === 0 ? (
                    <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                        There is no proforma invoice!
                    </Typography>
                ) : (
                    <Box sx={{ width: '100%', padding: '5%' }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </Box>
                )}
            </List>
        </Paper>
    );
}