import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip'
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useAuth0 } from "@auth0/auth0-react";
import { PopupMenu } from "react-simple-widgets";
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { config } from '../../../config';

export default function Layout({ children }) {
    const navigate = useNavigate();
    const { logout, user } = useAuth0();
    const organization = user[`${config.auth0.audience}/organization`];
    const roles = user[`${config.auth0.audience}/roles`];
    return (
        <Grid container spacing={1} style={{ height: '100vh', padding: 1, backgroundColor: '#f0f2f4' }}>
            <Grid item xs={0.5}>
                <Paper style={{ height: '100%', backgroundColor: '#3b434f', display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            padding: '30%',
                            backgroundColor: ' #586374',
                            '&:hover': {
                                backgroundColor: '#3b434f'
                            },
                            alignSelf: "flex-start"
                        }}
                        alt="Approx"
                        onClick={() => navigate('/')}
                        src="https://stapprox.blob.core.windows.net/public/logo.png"
                    />
                    <Box
                        sx={{
                            alignSelf: "flex-end",
                            textAlign: "center",
                        }}>
                        <Box
                            sx={{
                                width: '100%',
                                padding: '20%',
                                backgroundColor: ' #586374',
                                '&:hover': {
                                    backgroundColor: '#3b434f'
                                },
                                textAlign: "center"
                            }}
                            alt="Pricings"
                            onClick={() => navigate('/pricing')}
                        >
                            <Tooltip title="Pricings">
                                <AutoStoriesIcon
                                    style={{ fontSize: 30, color: "white" }}
                                />
                            </Tooltip>

                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                padding: '20%',
                                backgroundColor: ' #586374',
                                '&:hover': {
                                    backgroundColor: '#3b434f'
                                },
                                textAlign: "center"
                            }}
                            alt="Proforma Invoices"
                            onClick={() => navigate('/proforma')}
                        >
                            <Tooltip title="Proforma Invoices">
                                <ReceiptIcon style={{ fontSize: 30, color: "white" }} />
                            </Tooltip>
                        </Box>
                        <PopupMenu>
                            <Box
                                sx={{
                                    width: '100%',
                                    padding: '20%',
                                    backgroundColor: ' #586374',
                                    '&:hover': {
                                        backgroundColor: '#3b434f'
                                    },
                                    textAlign: "center",
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                alt={user.name}
                            >

                                <Tooltip title={user.name}>
                                    <Avatar
                                        sx={{ bgcolor: deepOrange[500], height: 30, width: 30, display: 'flex', justifyContent: 'center', fontSize: 15 }}>
                                        {user.name.toUpperCase()[0]}
                                    </Avatar>
                                </Tooltip>
                            </Box>

                            <Box sx={{ width: 500, height: 250 }}>
                                <Card variant="outlined" style={{ width: '100%', height: '100%' }}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', flexDirection: 'center', justifyContent: 'space-between', minWidth: '100%' }}>
                                            <Avatar sx={{ bgcolor: deepOrange[500], height: 70, width: 70, fontSize: 35 }}>{user.name.toUpperCase()[0]}</Avatar>
                                            <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
                                                {organization?.display_name}
                                            </Typography>
                                        </Box>

                                        <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
                                            {user.name}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {user.email}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {user.sub}
                                        </Typography>
                                        <Button variant='outlined' color='error' size="large" onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
                                        {roles && roles.find(x => x === config.auth0.adminRole)
                                            ? <Button onClick={() => navigate('/admin')}>Admin Panel</Button> : <></>}
                                    </CardContent>
                                </Card>
                            </Box>
                        </PopupMenu>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={11.5} sx={{ padding: 1 }}>
                {children}
            </Grid>
        </Grid >
    );
};

