import React, { useMemo, useState } from 'react';
import Layout from '../common/components/Layout';
import Grid from '@mui/material/Grid';
import PricingLibraryList from '../pricing/components/PricingLibraryList';
import PricingList from '../pricing/components/PricingList';
import PricingDetail from '../pricing/components/PricingDetail';
import { v4 as guid } from 'uuid';

export default function PricingPage() {
    const [selectedPricingLibrary, setSelectedPricingLibrary] = useState();
    const [selectedPricingID, setSelectedPricingID] = useState();
    const [validDate, setValidDate] = useState();
    const [pricingListRenderID, setPricingListRenderID] = useState(guid())
    const [libraryListRenderID, setLibraryListRenderID] = useState(guid())

    const refreshLibraryList = useMemo(() => {
        return () => setLibraryListRenderID(guid());
    }, [setLibraryListRenderID])


    const handleResetPricingSelect = () => {
        setSelectedPricingID(undefined);
    }

    const handleRefreshPricingList = () => {

        setPricingListRenderID(guid());
    }

    const handlePricingLibrarySelect = (pricingLibrary, validDate) => {
        setSelectedPricingLibrary(pricingLibrary);
        setSelectedPricingID(undefined);
        setValidDate(validDate);
        handleResetPricingSelect();
        handleRefreshPricingList();
    }

    const handleResetPricingLibrarySelect = () => {
        setSelectedPricingLibrary(undefined);
        setSelectedPricingID(undefined);
        setValidDate(undefined);
    }

    const handlePricingListClick = (pricing) => {
        setSelectedPricingID(pricing?.id);
    }

    return (
        <Layout>
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={2}>
                    <PricingLibraryList
                        handleResetPricingLibrarySelect={handleResetPricingLibrarySelect}
                        handlePricingLibrarySelect={handlePricingLibrarySelect}
                        selectedPricingLibrary={selectedPricingLibrary}
                        selectedValidDate={validDate}
                        libraryListRenderID={libraryListRenderID}
                        refreshLibraryList={refreshLibraryList}
                    />
                </Grid>
                <Grid item xs={4} style={{ height: '100%' }}>
                    <PricingList
                        selectedPricingLibrary={selectedPricingLibrary}
                        setSelectedPricing={handlePricingListClick}
                        selectedValidDate={validDate}
                        pricingListRenderID={pricingListRenderID}
                        refreshLibraryList={refreshLibraryList}
                    />
                </Grid>
                <Grid item xs={6} style={{ height: '100%' }}>
                    <PricingDetail
                        selectedPricingLibrary={selectedPricingLibrary}
                        selectedPricingID={selectedPricingID}
                        handleRefreshPricingList={handleRefreshPricingList}
                        handleResetPricingSelect={handleResetPricingSelect}
                        refreshLibraryList={refreshLibraryList}
                    />
                </Grid>
            </Grid>
        </Layout>
    );
}