import React, { useState } from 'react';
import PricingLibraryList from '../pricing/components/PricingLibraryList';
import PricingList from '../pricing/components/PricingList';

import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle

} from '@mui/material';

export default function SelectPricingModal({ open, setIsOpen, handlePricingSelect, selectPricingModalKey }) {
    const [selectedPricingLibrary, setSelectedPricingLibrary] = useState();
    const [validDate, setValidDate] = useState();
    const [pricing, setPricing] = useState();

    const handlePricingLibrarySelect = (pricingLibrary, validDate) => {
        setSelectedPricingLibrary(pricingLibrary);
        setValidDate(validDate);
    }

    const handleResetPricingLibrarySelect = () => {
        setSelectedPricingLibrary(undefined);
        setValidDate(undefined);
        setPricing(undefined);
    }

    const handlePricingListClick = (selectedPricing) => {
        setPricing(selectedPricing)
    }

    const handleCancel = () => {
        handleResetPricingLibrarySelect();
        setIsOpen(false);
    };

    const handleSave = () => {
        handlePricingSelect(pricing);
        setIsOpen(false);
    };

    return (
        <Dialog key={selectPricingModalKey} open={open} onClose={handleCancel} maxWidth='lg' fullWidth>
            <DialogTitle>
                Select Pricing
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ height: '100%' }}>
                    <Grid item xs={3}>
                        <PricingLibraryList
                            handleResetPricingLibrarySelect={handleResetPricingLibrarySelect}
                            handlePricingLibrarySelect={handlePricingLibrarySelect}
                            selectedPricingLibrary={selectedPricingLibrary}
                            selectedValidDate={validDate}
                        />
                    </Grid>
                    <Grid item xs={9} style={{ height: '100%' }}>
                        <PricingList
                            selectedPricingLibrary={selectedPricingLibrary}
                            setSelectedPricing={handlePricingListClick}
                            selectedValidDate={validDate}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Select
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}