import React, { useState, useEffect, useCallback } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelIcon from '@mui/icons-material/Label';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete'
import UploadFilesDialog from '../../common/components/UploadFilesDialog'
import { v4 as guid } from 'uuid';
import { fileToBase64 } from '../../common/helpers/FileToBase64Helper';

function WorkGroupTypeList({ takeoffTemplateID, projectID, hidden }) {
  const approxAPIClient = ApproxAPIClient();
  const [workGroupTypes, setWorkGroupTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [originalFiles, setOriginalFiles] = useState(undefined);
  const [importOriginalFileIsOpen, setImportOriginalFileIsOpen] = useState(false);
  const [selectedWorkGroupTypeID, setSelectedWorkGroupTypeID] = useState();
  const [originalFileListRenderID, setOriginalFileListRenderID] = useState(guid())

  const navigate = useNavigate();

  const handleImportOriginalFileClickOpen = () => {
    setImportOriginalFileIsOpen(true);
  };

  const handleImportOriginalFileSuccess = () => {
    setOriginalFileListRenderID(guid());
  }

  const handleOriginalFileDelete = async (file) => {
    setIsLoading(true);
    await approxAPIClient.deleteOriginalFile(file.id);
    setOriginalFileListRenderID(guid());
    setIsLoading(false);
  }

  const handleOriginalFileDownload = async (file) => {
    setIsLoading(true);
    const response = await approxAPIClient.downloadTenantFileAsBase64ByBlobID(file.blobID);
    if (response.isSuccessful) {
      const a = document.createElement("a");
      a.href = `data:${response.data.contentType};base64,${response.data}`;
      a.download = `${file.name}.${file.extension}`;
      a.click();
    } else {
      alert(response.error);
    }
    setIsLoading(false);
  }

  const goToWorkOrders = async () => {
    const queryParams = {
      workGroupTypeID: selectedWorkGroupTypeID,
      projectID: projectID
    };

    navigate(`workOrder?${new URLSearchParams(queryParams).toString()}`);
  }

  const handleListItemClick = useCallback((e, node) => {
    setSelectedWorkGroupTypeID(node.id);
  }, []);

  useEffect(() => {
    if (!selectedWorkGroupTypeID || !projectID) {
      return;
    }

    setIsLoading(true);

    approxAPIClient.fetchOriginalFilesByProjectIDWorkGroupTypeID(projectID, selectedWorkGroupTypeID).then((response) => {
      if (response.isSuccessful) {
        setOriginalFiles(response.data);
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    });

  }, [selectedWorkGroupTypeID, projectID, approxAPIClient, originalFileListRenderID])

  useEffect(() => {
    if (!takeoffTemplateID) {
      return;
    }

    setIsLoading(true);

    approxAPIClient.fetchWorkGroupTypesByTakeoffTemplateID(takeoffTemplateID).then((response) => {
      if (response.isSuccessful === true) {
        let workGroupTypes = response.data;

        workGroupTypes.sort((a, b) => a.sorting - b.sorting);
        setWorkGroupTypes(workGroupTypes);

        if (workGroupTypes.length > 0) {
          handleListItemClick(null, response.data[0]);
        }
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    });

  }, [approxAPIClient, handleListItemClick, takeoffTemplateID])

  return (<>
    {
      selectedWorkGroupTypeID && projectID ? <UploadFilesDialog
        isOpen={importOriginalFileIsOpen}
        setIsOpen={setImportOriginalFileIsOpen}
        isMultiple={true}
        handleFilesSet={async (files) => {
          setIsLoading(true);
          try {
            const input = {
              projectID: projectID,
              workGroupTypeID: selectedWorkGroupTypeID,
              files: await Promise.all(files.map(async (file) => {
                return {
                  name: file.name,
                  ext: file.ext,
                  base64File: await fileToBase64(file.buffer)
                };
              }))
            };

            await approxAPIClient.bulkCreateOriginalFiles(input);
            handleImportOriginalFileSuccess();
          } catch (error) {
            alert(error);
          }
          setIsLoading(false);
        }}

      /> : <></>
    }
    <Grid container spacing={1} style={{ height: '100%' }} hidden={hidden}>
      <Grid item xs={8}>
        <Paper style={{ height: '100%' }}>
          <List
            component="nav"
            aria-label="work group type list"
            style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Stack spacing={1} direction="row">
                  <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                    Work Groups
                  </Typography>
                </Stack>
              </ListSubheader>
            }
          >
            {!takeoffTemplateID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
              Please select a project
            </Typography> :
              !isLoading && workGroupTypes ? workGroupTypes.map(x =>
                <ListItemButton
                  selected={x.id === selectedWorkGroupTypeID}
                  onClick={(e) => handleListItemClick(e, x)}
                  key={x.id}
                >
                  <ListItemIcon>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={x.name}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '50px',
                      mb: '2px',
                      color: 'black'
                    }}

                  />
                </ListItemButton>
              ) : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Box style={{ height: '20%' }}>
          <Button variant='contained' fullWidth disabled={isLoading || !selectedWorkGroupTypeID || !projectID} onClick={goToWorkOrders}>Work Orders</Button>
        </Box>
        <Paper style={{ height: '80%' }}>
          <List
            component="nav"
            aria-label="original files list"
            style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                  <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                    Files
                  </Typography>
                  <Button variant="outlined" size="small" disabled={isLoading || !projectID} onClick={handleImportOriginalFileClickOpen}>Upload</Button>
                </Stack>
              </ListSubheader>
            }
          >
            {!selectedWorkGroupTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
              Please select a work group
            </Typography> :
              !isLoading && originalFiles && originalFiles.length > 0 ? originalFiles.map(originalFile =>
                <ListItem
                  key={originalFile.id}
                >
                  <ListItemText
                    primary={`${originalFile.name}.${originalFile.extension}`}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '30px',
                      mb: '2px',
                      color: 'black'
                    }}
                  />
                  <IconButton onClick={() => handleOriginalFileDownload(originalFile)} >
                    <FileDownloadIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOriginalFileDelete(originalFile)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ) : !isLoading && originalFiles && originalFiles.length === 0 ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                There is no original file
              </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>}
          </List>
        </Paper>
      </Grid>
    </Grid>
  </>
  );
}

export default WorkGroupTypeList;
