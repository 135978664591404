import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined'
import { fileToBase64 } from '../../common/helpers/FileToBase64Helper';


export default function CreateProformaInvoiceDialog({ createProformaInvoiceIsOpen, setCreateProformaInvoiceIsOpen, handleCreateProformaInvoiceSuccess }) {
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();


    const handleCreateProformaInvoiceClose = () => {
        setFile(undefined);
        setCreateProformaInvoiceIsOpen(false);
    };

    const handleSelectFile = (e) => {

        let files = e?.target?.files;
        let file = files && files.length > 0 ? files[0] : undefined;
        if (file) {
            setFile(file);
        }
    };

    return <Dialog
        open={createProformaInvoiceIsOpen}
        onClose={handleCreateProformaInvoiceClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                setIsLoading(true);
                event.preventDefault();
                var reader = new FileReader();

                reader.onload = async () => {
                    const base64Data = await fileToBase64(reader.result);
                    const input = {
                        ...formJson,
                        base64File: base64Data
                    }

                    await approxAPIClient.createProformaInvoice(input).then((res) => {
                        if (res.isSuccessful === true) {
                            handleCreateProformaInvoiceSuccess();
                        } else {
                            alert(res.error);
                        }
                        setIsLoading(false);
                        handleCreateProformaInvoiceClose();
                    })
                };

                reader.readAsArrayBuffer(file);
            },
        }}
    >
        <DialogTitle>Create Proforma Invoice</DialogTitle>
        {
            !isLoading ? <>
                <DialogContent>
                    <InputLabel id="proformaInvoiceCompanyName-label">Company Name</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="proformaInvoiceCompanyName"
                        name="companyName"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <InputLabel id="proformaInvoiceValidFrom-label">Valid From</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="proformaInvoiceValidFrom"
                        name="validFrom"
                        type="date"
                        fullWidth
                        variant="standard"
                    />
                    <InputLabel id="proformaInvoiceValidTo-label">Valid To</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="proformaInvoiceValidTo"
                        name="validTo"
                        type="date"
                        fullWidth
                        variant="standard"
                    />
                    <InputLabel id="proformaInvoiceFile-label">File</InputLabel>
                    <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        id="proformaInvoiceFile"
                        value={file?.name}
                        disabled
                        required
                        InputProps={{
                            endAdornment: (
                                <IconButton component="label">
                                    <FileUploadOutlined />
                                    <input
                                        styles={{ display: "none" }}
                                        type="file"
                                        hidden
                                        accept=".pdf"
                                        onChange={(e) => handleSelectFile(e)}
                                        name="file"
                                    />
                                </IconButton>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateProformaInvoiceClose}>Cancel</Button>
                    <Button disabled={!file} type="submit">Create</Button>
                </DialogActions>


            </> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        }
    </Dialog>
}