const fileToBase64 = (arrayBuffer) => {
    return new Promise((resolve, reject) => {
        try {
            const binary = new Uint8Array(arrayBuffer);
            let binaryString = "";
            binary.forEach((byte) => (binaryString += String.fromCharCode(byte)));
            const base64 = btoa(binaryString);
            resolve(base64);
        } catch (error) {
            reject(error);
        }
    });
};

const base64ToBytes = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
};

export { base64ToBytes, fileToBase64 };