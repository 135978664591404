import React, { useState, useEffect, useCallback } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'

import { useSearchParams } from 'react-router-dom'
import WorkOrderType from '../../../constants/WorkOrderType'

import {
    ListItemIcon,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Skeleton,
    Typography,
    Stack,
    Box,
    ListSubheader,
    Grid,
    ListItem,
    Checkbox,
    Button,
} from '@mui/material';
import { v4 as guid } from 'uuid';

export default function Copy({ workTypeID, hidden, completedWorkOrderListRenderID, setCompletedWorkOrderListRenderID, setWorkOrderListRenderID }) {

    const approxAPIClient = ApproxAPIClient();
    const [completedWorkOrders, setCompletedWorkOrders] = useState();
    const [nonCompletedWorkOrders, setNonCompletedWorkOrders] = useState();
    const [selectedCompletedWorkOrder, setSelectedCompletedWorkOrder] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const projectID = searchParams.get("projectID");
    const [selectedNonCompletedWorkOrderIDs, setSelectedNonCompletedWorkOrderIDs] = useState([]);
    const [nonCompletedWorkOrderListRenderID, setNonCompletedWorkOrderListRenderID] = useState(guid())

    const handleSelectAll = () => {
        let userWorkOrders = nonCompletedWorkOrders;
        if (selectedNonCompletedWorkOrderIDs.length < userWorkOrders.length) {
            const allWorkOrderIDs = userWorkOrders.map(x => x.id);
            setSelectedNonCompletedWorkOrderIDs(allWorkOrderIDs);
        } else {
            setSelectedNonCompletedWorkOrderIDs([]);
        }
    }

    const handleToggleCheckBox = (workOrderID) => () => {
        const index = selectedNonCompletedWorkOrderIDs.indexOf(workOrderID);
        if (index === -1) {
            setSelectedNonCompletedWorkOrderIDs([workOrderID, ...selectedNonCompletedWorkOrderIDs]);
        } else {
            const otherWorkOrderIDs = [...selectedNonCompletedWorkOrderIDs].filter(x => x !== workOrderID);
            setSelectedNonCompletedWorkOrderIDs([...otherWorkOrderIDs]);
        }
    };

    const handleListItemClick = useCallback((e, node) => {
        setSelectedCompletedWorkOrder(node);
    }, []);

    const handleCopySubmit = async () => {
        setIsLoading(true);

        const input = {
            sourceWorkOrderID: selectedCompletedWorkOrder?.id,
            targetWorkOrderIDs: selectedNonCompletedWorkOrderIDs,
        }

        await approxAPIClient.copyTakeoffWorkOrderData(input).then((res) => {
            if (res.isSuccessful === true) {
                setCompletedWorkOrderListRenderID(guid());
                setNonCompletedWorkOrderListRenderID(guid());
                setWorkOrderListRenderID(guid());
            } else {
                alert(res.error);
            }

            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!workTypeID || !projectID) {
            return;
        }

        setIsLoading(true);
        setNonCompletedWorkOrders([]);
        approxAPIClient.fetchNonCompletedUserWorkOrdersByWorkTypeIDProjectID(workTypeID, projectID, WorkOrderType.Takeoff).then((response) => {
            if (response.isSuccessful === true) {
                setNonCompletedWorkOrders(response.data);
                setSelectedNonCompletedWorkOrderIDs([]);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });

    }, [approxAPIClient, workTypeID, projectID, nonCompletedWorkOrderListRenderID, selectedCompletedWorkOrder])

    useEffect(() => {
        if (!workTypeID || !projectID) {
            return;
        }

        setIsLoading(true);
        setCompletedWorkOrders([]);
        approxAPIClient.fetchCompletedUserWorkOrdersByWorkTypeIDProjectID(workTypeID, projectID, WorkOrderType.Takeoff).then((response) => {
            if (response.isSuccessful === true) {
                setCompletedWorkOrders(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });

    }, [approxAPIClient, handleListItemClick, workTypeID, projectID, completedWorkOrderListRenderID])

    return (
        <Grid hidden={hidden} container spacing={1} style={{ height: '100%' }}>
            <Grid item xs={5}>
                <Paper style={{ height: '100%', width: '100%' }}>
                    <Stack spacing={0} direction="row" sx={{ width: '100%', height: '100%' }} justifyContent='space-between'>
                        <List
                            component="nav"
                            aria-label="work group type list"
                            style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', width: '100%' }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                                        <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                            Completed User Work Orders
                                        </Typography>
                                    </Stack>
                                </ListSubheader>
                            }
                        >
                            {!workTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                                Please select a work type
                            </Typography> :
                                !isLoading && completedWorkOrders && completedWorkOrders.length !== 0 ? completedWorkOrders.map(x => {

                                    let parentList = [];
                                    let parent = x.property.parent;

                                    while (parent !== null) {
                                        parentList.push(`${parent.code} ${parent.name}`);
                                        parent = parent.parent;
                                    }

                                    parentList.reverse();
                                    return (
                                        <ListItemButton
                                            selected={x.id === selectedCompletedWorkOrder?.id}
                                            onClick={(e) => handleListItemClick(e, x)}
                                            key={x.id}
                                        >
                                            <ListItemText
                                                primary={`${x.property.code} ${x.property.name}`}
                                                primaryTypographyProps={{
                                                    fontSize: 15,
                                                    fontWeight: 'medium',
                                                    lineHeight: '50px',
                                                    mb: '2px',
                                                    color: 'black'
                                                }}
                                                secondary={parentList.join('/')}
                                                secondaryTypographyProps={{
                                                    fontWeight: 'medium',
                                                    mb: '2px',
                                                    textAlign: 'left'
                                                }}
                                            />
                                        </ListItemButton>)
                                }

                                ) : !isLoading && completedWorkOrders && completedWorkOrders.length === 0 ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                                    There is no work order!
                                </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                </Box>}
                        </List>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={5}>
                <Paper style={{ height: '100%', width: '100%' }}>
                    <List
                        component="nav"
                        aria-label="work group type list"
                        style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', width: '100%' }}
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                                    <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                        Appliable User Work Orders
                                    </Typography>
                                    {nonCompletedWorkOrders && selectedNonCompletedWorkOrderIDs && nonCompletedWorkOrders.length > 0 ? <Box
                                        onClick={() => handleSelectAll()}>
                                        {<Checkbox
                                            sx={{ height: "100%" }}
                                            label='Select All'
                                            checked={selectedNonCompletedWorkOrderIDs.length === nonCompletedWorkOrders.length}
                                        />}
                                    </Box> : <></>}
                                </Stack>
                            </ListSubheader>
                        }
                    >
                        {!workTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                            Please select a work type
                        </Typography> : !selectedCompletedWorkOrder ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                            Please select a source work order
                        </Typography> :
                            !isLoading && nonCompletedWorkOrders && nonCompletedWorkOrders.length !== 0 ? nonCompletedWorkOrders.map(x => {

                                let parentList = [];
                                let parent = x.property.parent;

                                while (parent !== null) {
                                    parentList.push(`${parent.code} ${parent.name}`);
                                    parent = parent.parent;
                                }

                                parentList.reverse();
                                return (
                                    <ListItem
                                        key={x.id}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                onClick={handleToggleCheckBox(x.id)}
                                                checked={selectedNonCompletedWorkOrderIDs.indexOf(x.id) !== -1}
                                                disabled={x.isReadOnly}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${x.property.code} ${x.property.name}`}
                                            primaryTypographyProps={{
                                                fontSize: 15,
                                                fontWeight: 'medium',
                                                lineHeight: '50px',
                                                mb: '2px',
                                                color: 'black'
                                            }}
                                            secondary={parentList.join('/')}
                                            secondaryTypographyProps={{
                                                fontWeight: 'medium',
                                                mb: '2px',
                                                textAlign: 'left'
                                            }}
                                        />
                                    </ListItem>)
                            }

                            ) : !isLoading && nonCompletedWorkOrders && nonCompletedWorkOrders.length === 0 ? <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                                There is no work order!
                            </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </Box>}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper style={{ height: '100%', width: '100%', padding: 10 }}>
                    <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                        <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                            Actions
                        </Typography>
                    </Stack>
                    <Button
                        onClick={handleCopySubmit}
                        variant='outlined'
                        disabled={selectedNonCompletedWorkOrderIDs.length === 0 || isLoading}
                        fullWidth
                    >Copy</Button>
                </Paper>
            </Grid>
        </Grid>
    );
}