const ProformaCalculationMethod = {
    None: 0,
    Min: 1,
    Max: 2,
    Mid: 3,
    Avg: 4,
}

const ProformaCalculationMethodValTypeDict = {
    1: "Min",
    2: "Max",
    3: "Mid",
    4: "Avg",
}

export { ProformaCalculationMethod, ProformaCalculationMethodValTypeDict };