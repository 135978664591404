import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import ApproxAPIClient from '../../../ApproxAPIClient';
import Skeleton from '@mui/material/Skeleton'
import WeekendIcon from '@mui/icons-material/Weekend';
import ListItemIcon from '@mui/material/ListItemIcon';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ImportPropertyDialog from './ImportPropertyDialog';
import AddPropertyDialog from './AddPropertyDialog';
import { v4 as guid } from 'uuid';


function PropertyList({ projectID, hidden }) {
  const [tree, setTree] = useState();
  const [projectProperties, setProjectProperties] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const approxAPIClient = ApproxAPIClient();
  const [importPropertyIsOpen, setImportPropertyIsOpen] = useState(false);
  const [addPropertyIsOpen, setAddPropertyIsOpen] = useState(false);
  const [propertyListRenderID, setPropertyListRenderID] = useState(guid())

  const handleImportPropertySuccess = () => {
    setPropertyListRenderID(guid());
  }

  const handleAddPropertySuccess = () => {
    setSelectedNode(undefined);
    setPropertyListRenderID(guid());
  }

  const handleImportPropertyClickOpen = () => {
    setImportPropertyIsOpen(true);
  };

  const handleAddPropertyClickOpen = () => {
    setAddPropertyIsOpen(true);
  };

  const handleDeletePropertyClick = () => {
    setIsLoading(true);

    const input = {
      propertyID: selectedNode.id
    }

    approxAPIClient.deleteProperty(input).then((res) => {
      if (res.isSuccessful === true) {
        setPropertyListRenderID(guid());
        setSelectedNode(undefined);
      } else {
        alert(res.error);
      }
    })

    setIsLoading(false);
  }

  const fillChildren = useCallback((parent, properties) => {
    let children = properties.filter(x => x.parentID === parent.id);
    children.forEach(child => {
      child.parent = parent;
    })

    if (parent) {
      parent.children = children;
    }
    children.forEach(element => {
      fillChildren(element, properties);
    })
  }, []);

  useEffect(() => {
    if (!projectID) {
      return;
    }

    setIsLoading(true);
    approxAPIClient.fetchProjectPropertiesByProjectID(projectID, null).then((response) => {
      if (response.isSuccessful === true) {
        setProjectProperties(response.data);
        let parent = response.data.find(x => !x.parentID || x.parentID === null);
        fillChildren(parent, response.data);
        setTree(parent);
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    });
  }, [projectID, approxAPIClient, fillChildren, propertyListRenderID])

  const handleNodeClick = (node) => {
    setSelectedNode(node);
  };

  const isNodeSelected = (node) => node.id === selectedNode?.id;

  const renderTree = (parentNode) => (
    <List disablePadding sx={{ pl: 2 }}>
      {parentNode.children.map((node) => (
        <React.Fragment key={node.id}>
          <ListItemButton onClick={() => handleNodeClick(node)} selected={isNodeSelected(node)}>
            {
              (!node.children || node.children.length === 0) ?
                <ListItemIcon>
                  <WeekendIcon />
                </ListItemIcon> :
                <ListItemIcon>
                  <ViewColumnIcon />
                </ListItemIcon>
            }
            <ListItemText
              primary={`${node.code} ${node.name}`}
            />
          </ListItemButton>
          {node.children && node.children.length > 0 && (
            renderTree(node)
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <>
      <ImportPropertyDialog
        handleImportPropertySuccess={handleImportPropertySuccess}
        setImportPropertyIsOpen={setImportPropertyIsOpen}
        importPropertyIsOpen={importPropertyIsOpen}
        projectID={projectID}
      />
      {projectID ? <AddPropertyDialog
        handleAddPropertySuccess={handleAddPropertySuccess}
        setAddPropertyIsOpen={setAddPropertyIsOpen}
        addPropertyIsOpen={addPropertyIsOpen}
        projectID={projectID}
        selectedProperty={selectedNode}
      /> : <></>}

      <Grid container spacing={1} style={{ height: '100%' }} hidden={hidden}>
        <Grid item xs={8} height='100%'>
          <Paper style={{ height: '100%' }}>
            {!projectID ?
              <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                Please select a project
              </Typography> :
              (!isLoading && projectProperties && projectProperties.length > 0 && tree) ? (
                <Box sx={{ height: '100%' }}>
                  {<ListSubheader component="div" id="nested-list-subheader">
                    <Stack spacing={1} direction="row" style={{ display: 'flex', justifyContent: 'left' }}>
                      <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary">
                        Properties
                      </Typography>
                    </Stack>
                  </ListSubheader>
                  }
                  {
                    <List sx={{ height: '95%', overflowY: 'auto' }}>
                      {
                        <React.Fragment key={tree.id} >
                          <ListItemButton onClick={() => handleNodeClick(tree)} selected={isNodeSelected(tree)}>
                            {
                              (!tree.children || tree.children.length === 0) ?
                                <ListItemIcon>
                                  <WeekendIcon />
                                </ListItemIcon> :
                                <ListItemIcon>
                                  <ViewColumnIcon />
                                </ListItemIcon>
                            }
                            <ListItemText
                              primary={`${tree.code} ${tree.name}`}
                            />
                          </ListItemButton>
                          {tree.children && tree.children.length > 0 && (
                            renderTree(tree)
                          )}
                        </React.Fragment>
                      }
                    </List>
                  }
                </Box>
              ) : !isLoading && projectProperties && projectProperties.length === 0 && !tree ?
                <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                  There is no property!
                </Typography> : (
                  <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Button variant='contained' fullWidth disabled={isLoading || !projectID} style={{ margin: 5 }} onClick={handleImportPropertyClickOpen}>Import</Button>
            <Button variant='contained' fullWidth color='success' disabled={isLoading || !projectID} style={{ margin: 5 }} onClick={handleAddPropertyClickOpen}>Add</Button>
            <Button variant='contained' fullWidth color='error' disabled={isLoading || !selectedNode || !tree} style={{ margin: 5 }} onClick={handleDeletePropertyClick}>Delete</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default PropertyList;
