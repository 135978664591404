import React, { useState, useEffect } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField'
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SelectPricingModal from '../../pricing/SelectPricingModal';
import PricingScaleModal from '../components/PricingScaleModal';
import { v4 as guid } from 'uuid';

export default function PricingSection({ isReadOnly, workOrderID, rowListRenderID, calculationAreaTypeID, renewPricingGroupsRenderID }) {
  const [calculationAreaTypePricings, setCalculationAreaTypePricings] = useState(undefined);
  const [calculationAreaGroups, setCalculationAreaGroups] = useState();
  const [selectedCalculationAreaGroupID, setSelectedCalculationAreaGroupID] = useState();
  const [sectionTotalAmount, setSectionTotalAmount] = useState(undefined);
  const [calculationAreaResult, setCalculationAreaResult] = useState();
  const [calculationAreaType, setCalculationAreaType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCalculationGroupInputOpen, setIsAddCalculationGroupInputOpen] = useState(false);
  const [addCalculationGroupInputValue, setAddCalculationGroupInputValue] = useState("");
  const [selectPricingModalIsOpen, setSelectPricingModalIsOpen] = useState(false);
  const [pricingScaleModalIsOpen, setPricingScaleModalIsOpen] = useState(false);
  const [pricingSectionRenderID, setPricingSectionRenderID] = useState(guid())
  const [selectedPricing, setSelectedPricing] = useState(undefined);
  const approxAPIClient = ApproxAPIClient();

  const handlePricingScaleSubmit = (setScale) => {
    const input = {
      pricingID: selectedPricing.id,
      scale: setScale,
      workOrderCalculationAreaGroupID: selectedCalculationAreaGroupID
    };

    setIsLoading(true);
    approxAPIClient.createCalculationAreaGroupPricing(input).then((res) => {
      if (res.isSuccessful !== true) {
        alert(res.error);
      } else {
        setPricingSectionRenderID(guid());
        setSelectedPricing(undefined);
      }
      setIsLoading(false);
    })
  }

  const handlePricingSelect = (pricing) => {
    setSelectedPricing(pricing);
    setPricingScaleModalIsOpen(true);
  }

  const handleDeleteCalculationAreaPricing = (id) => {
    const input = {
      id: id
    };

    setIsLoading(true);
    approxAPIClient.deleteCalculationAreaGroupPricingByID(input).then((res) => {
      if (res.isSuccessful !== true) {
        alert(res.error);
      } else {
        setPricingSectionRenderID(guid());
      }
      setIsLoading(false);
    })
  }

  const addPricingRow = () => {
    setSelectedPricing(undefined);
    setSelectPricingModalIsOpen(true);
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      approxAPIClient.fetchWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID(workOrderID, calculationAreaTypeID).then((response) => {
        if (response.isSuccessful === true) {
          setCalculationAreaGroups(response.data);
          if (response.data?.length > 0) {
            setSelectedCalculationAreaGroupID(response.data[0].id);
          }
        } else {
          alert(response.error);
        }

      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [approxAPIClient, calculationAreaTypeID, workOrderID])

  useEffect(() => {
    if (!selectedCalculationAreaGroupID || !calculationAreaGroups) {
      return;
    }

    try {
      setIsLoading(true);
      approxAPIClient.fetchCalculationAreaGroupPricingsByCalculationAreaGroupID(selectedCalculationAreaGroupID).then((response) => {
        if (response.isSuccessful === true) {
          setCalculationAreaTypePricings(response.data);
        } else {
          alert(response.error);
        }

      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [approxAPIClient, calculationAreaTypeID, workOrderID, selectedCalculationAreaGroupID, calculationAreaGroups, pricingSectionRenderID])

  useEffect(() => {
    try {
      setIsLoading(true);
      approxAPIClient.fetchCalculationAreaResultByWorkOrderIDCalculationAreaTypeID(workOrderID, calculationAreaTypeID).then((response) => {
        if (response.isSuccessful === true) {
          setCalculationAreaResult(response.data);
        } else {
          alert(response.error);
        }

        let total = 0;
        response.data.forEach(x => {
          total += x.result;
        });

        setSectionTotalAmount(total);
      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [approxAPIClient, calculationAreaTypeID, workOrderID, rowListRenderID])

  useEffect(() => {
    try {
      setIsLoading(true);
      approxAPIClient.fetchCalculationAreaTypeByID(calculationAreaTypeID).then((response) => {
        if (response.isSuccessful === true) {
          setCalculationAreaType(response.data);
        } else {
          alert(response.error);
        }

      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [approxAPIClient, calculationAreaTypeID])

  const rowResultColor = (amount) =>
    amount > 0
      ? "#9AC38F"
      : amount < 0
        ? "#E1847E"
        : "#eee2d0";

  const handleAddCalculationGroupInputClose = () => {
    setIsAddCalculationGroupInputOpen(!isAddCalculationGroupInputOpen);
    setAddCalculationGroupInputValue("");
  }

  const handleAddCalculationGroupInputSubmit = () => {
    setIsLoading(true);
    const input = {
      workOrderID: workOrderID,
      calculationAreaTypeIDs: [calculationAreaTypeID],
      name: addCalculationGroupInputValue
    }

    approxAPIClient.createWorkOrderCalculationAreaGroup(input).then((res) => {
      if (res.isSuccessful === true) {
        setCalculationAreaGroups([...calculationAreaGroups, ...res.data]);
        renewPricingGroupsRenderID();
      } else {
        alert(res.error);
      }
    }).then(() => {
      setIsLoading(false);
      handleAddCalculationGroupInputClose();

    })
  }

  const handleDeleteWorkOrderCalculationAreaGroup = (id) => {
    setIsLoading(true);
    const input = {
      workOrderCalculationAreaGroupID: id
    }

    approxAPIClient.deleteWorkOrderCalculationAreaGroup(input).then((res) => {
      if (res.isSuccessful === true) {
        const undeleted = calculationAreaGroups.filter(x => x.id !== id);
        setCalculationAreaGroups([...undeleted]);
        renewPricingGroupsRenderID();
      } else {
        alert(res.error);
      }

    }).then(() => {
      setIsLoading(false);
    })
  }

  return (
    <>
      <PricingScaleModal open={pricingScaleModalIsOpen} setIsOpen={setPricingScaleModalIsOpen} handleSubmit={handlePricingScaleSubmit} pricingScaleModalKey={0} />
      <SelectPricingModal selectPricingModalKey={"0"} open={selectPricingModalIsOpen} setIsOpen={setSelectPricingModalIsOpen} handlePricingSelect={handlePricingSelect} />
      <Card sx={{ height: '30%', marginTop: 1, opacity: isReadOnly ? 0.60 : 1, pointerEvents: isReadOnly ? 'none' : 'initial' }}>
        {!calculationAreaType ? <Box sx={{ width: '100%', padding: '5%' }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box> : <>
          <CardHeader
            style={{
              backgroundColor: calculationAreaType.color,
              padding: 0,
              paddingBottom: 0
            }}
            title={<Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ color: 'white', width: '75%', marginLeft: 5 }} variant='div'>{calculationAreaType.name}</Typography>
              {sectionTotalAmount === undefined || isLoading ? <></> : <Typography variant='div' backgroundColor={rowResultColor(sectionTotalAmount)} color='white'
                sx={{ width: '15%', height: '100%', lineHeight: '200%', marginTop: 0, textAlign: 'center' }}>{Number(sectionTotalAmount).toFixed(
                  calculationAreaType.precision
                )}</Typography>}

              <IconButton onClick={addPricingRow} color='success' sx={{ width: '10%', height: '100%', lineHeight: '200%', marginTop: 0 }}>
                <AddIcon />
              </IconButton>
            </Box>}
          />
          <CardContent sx={{ height: '100%', padding: 0, display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '30%', borderRight: 2, borderColor: 'grey', height: '100%', padding: 0 }}>
              <List
                disablePadding sx={{ height: '90%', overflowY: 'auto', overflowX: 'hidden' }}
              >
                {
                  calculationAreaGroups && calculationAreaResult ? calculationAreaGroups.map(group => {
                    const groupResult = calculationAreaResult.filter(x => x.workOrderCalculationAreaGroupID === group.id);

                    let groupResultSum = 0;
                    groupResult.forEach(x => {
                      groupResultSum += (x.result ?? 0)
                    })

                    return (<ListItemButton
                      key={`CalculationAreaGroup#${group.id}`}
                      selected={selectedCalculationAreaGroupID === group.id}
                      onClick={() => setSelectedCalculationAreaGroupID(group.id)}
                      sx={{
                        backgroundColor: '#7c8799',
                        '&.Mui-selected': {
                          backgroundColor: '#586374',
                          '&:hover': {
                            backgroundColor: '#3b434f'
                          },
                        },
                        '&:hover': {
                          backgroundColor: '#3b434f'
                        },
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: '20%',
                      }}
                    >
                      <ListItemText
                        sx={{ width: '60%' }}
                        primary={group.name}
                        primaryTypographyProps={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}
                      />
                      {
                        <Box sx={{ width: '40%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {groupResultSum === undefined || isLoading ? <></> : <ListItemText
                            sx={{ width: '50%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: rowResultColor(groupResultSum) }}
                            primary={Number(groupResultSum).toFixed(calculationAreaType.precision)}
                            primaryTypographyProps={{ fontSize: '15px', fontWeight: 'bold', color: 'white' }}
                          />}
                          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWorkOrderCalculationAreaGroup(group.id)} sx={{ width: '50%' }}>
                            <DeleteIcon sx={{ color: 'white' }} />
                          </IconButton>
                        </Box>
                      }
                    </ListItemButton>)
                  }) : <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                }
                <ListItem sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', height: '20%' }}>
                  <IconButton
                    onClick={handleAddCalculationGroupInputClose}
                    hidden={isAddCalculationGroupInputOpen === true} color='success'>
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleAddCalculationGroupInputClose}
                    hidden={isAddCalculationGroupInputOpen === false} color='error' >
                    <CloseIcon />
                  </IconButton>
                  {<TextField
                    key={`AddCalculationAreaGroup#${calculationAreaTypeID}`}
                    hidden={isAddCalculationGroupInputOpen === false}
                    autoFocus
                    required
                    margin="dense"
                    variant="standard"
                    value={addCalculationGroupInputValue}
                    onChange={(e) => setAddCalculationGroupInputValue(e.target.value)}
                  />}
                  <IconButton onClick={handleAddCalculationGroupInputSubmit}>
                    <CheckIcon hidden={isAddCalculationGroupInputOpen === false} color='success' />
                  </IconButton>
                </ListItem>
              </List>
            </Box>
            <Box sx={{ width: '70%' }}>
              {!calculationAreaTypePricings || isLoading ? <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box> : calculationAreaTypePricings.length === 0 ? <Alert severity="warning">
                empty!
              </Alert> : <List sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden', paddingBottom: 2 }}>
                {
                  calculationAreaTypePricings.map((pricing) => (
                    <ListItem
                      key={`PricingRow#${pricing.id}`}
                      divider
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCalculationAreaPricing(pricing.id)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={pricing.scale}
                        primaryTypographyProps={{ fontSize: '12px' }}
                        sx={{ width: '5%' }}
                      />
                      <ListItemText
                        primary={pricing.pricing.code}
                        secondary={pricing.pricing.definition}
                        primaryTypographyProps={{ fontSize: '12px' }}
                        sx={{ width: '90%' }}
                      />
                    </ListItem>
                  ))
                }
              </List>
              }
            </Box>
          </CardContent>
        </>}
      </Card>
    </>
  );
}
