import { config } from './config';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback, useMemo } from 'react';

const ApproxAPIClient = () => {
    const { getAccessTokenSilently } = useAuth0();
    const baseURL = config.api.url;

    const callApi = useCallback(async (endpoint, params, body, method) => {
        let result = {
            data: undefined,
            isSuccessful: false,
            error: undefined
        }

        try {
            const token = await getAccessTokenSilently();
            let url = `${baseURL}/${endpoint}`;
            if (params) {
                url = `${url}?${new URLSearchParams(params)}`
            }
            var response = await axios({
                method: method,
                url: url,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                data: body ? JSON.stringify(body) : null,
            });

            result.data = response.data;
            result.isSuccessful = true;
            return result;
        } catch (error) {
            result.error = error.response ? error.response.data.Error : error.message;
            return result;
        }
    }, [getAccessTokenSilently, baseURL]);

    const approxAPIClient = useMemo(() => {
        const endpoints = {
            GetWorkTypeByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkTypeByIDAsync",
                Method: "GET"
            },
            GetScreenTypeColorByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetScreenTypeColorByIDAsync",
                Method: "GET"
            },
            GetWorkOrderStateByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderStateByWorkOrderIDAsync",
                Method: "GET"
            },
            GetWorkTypeNamesByWorkGroupTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkTypeNamesByWorkGroupTypeIDAsync",
                Method: "GET"
            },
            GetPropertyTreeByPropertyID: {
                Controller: "Project",
                Endpoint: "GetPropertyTreeByChildPropertyIDAsync",
                Method: "GET"
            },
            GetWorkOrderByID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderByIDAsync",
                Method: "GET"
            },
            GetProjectPropertiesByProjectID: {
                Controller: "Project",
                Endpoint: "GetProjectPropertiesByProjectIDAsync",
                Method: "GET"
            },
            GetProjects: {
                Controller: "Project",
                Endpoint: "GetProjectsAsync",
                Method: "GET"
            },
            DownloadTenantFileAsBase64ByBlobID: {
                Controller: "Project",
                Endpoint: "DownloadTenantFileAsBase64ByBlobIDAsync",
                Method: "GET"
            },
            GetNextPrevAssignableWorkOrderIDByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetNextPrevAssignableWorkOrderIDByWorkOrderIDAsync",
                Method: "GET"
            },
            GetWorkOrderRowsByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderRowsByWorkOrderIDAsync",
                Method: "GET"
            },
            GetRowTypesByScreenTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetRowTypesByScreenTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaTypeIDsByWorkTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetCalculationAreaTypeIDsByWorkTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaTypeByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetCalculationAreaTypeByIDAsync",
                Method: "GET"
            },
            GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeID: {
                Controller: "WorkOrder",
                Endpoint: "GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaGroupPricingsByCalculationAreaGroupID: {
                Controller: "WorkOrder",
                Endpoint: "GetCalculationAreaGroupPricingsByCalculationAreaGroupIDAsync",
                Method: "GET"
            },
            GetWorkGroupTypesByTakeoffTemplateID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkGroupTypesByTakeoffTemplateIDAsync",
                Method: "GET"
            },
            GetTenantTakeoffTemplates: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetTenantTakeoffTemplatesAsync",
                Method: "GET"
            },
            GetTenantReportTemplates: {
                Controller: "ReportTemplate",
                Endpoint: "GetTenantReportTemplatesAsync",
                Method: "GET"
            },
            GetNonCompletedUserWorkOrdersByWorkTypeIDProjectID: {
                Controller: "WorkOrder",
                Endpoint: "GetNonCompletedUserWorkOrdersByWorkTypeIDProjectIDAsync",
                Method: "GET"
            },
            GetCompletedUserWorkOrdersByWorkTypeIDProjectID: {
                Controller: "WorkOrder",
                Endpoint: "GetCompletedUserWorkOrdersByWorkTypeIDProjectIDAsync",
                Method: "GET"
            },
            GetWorkOrdersByWorkTypeIDProjectID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrdersByWorkTypeIDProjectIDAsync",
                Method: "GET"
            },
            GetAllTenantUsers: {
                Controller: "WorkOrder",
                Endpoint: "GetAllTenantUsersAsync",
                Method: "GET"
            },
            UpsertRow: {
                Controller: "WorkOrder",
                Endpoint: "UpsertRowAsync",
                Method: "PUT"
            },
            DeleteRow: {
                Controller: "WorkOrder",
                Endpoint: "DeleteRowAsync",
                Method: "DELETE"
            },
            AssignWorkOrdersToUser: {
                Controller: "WorkOrder",
                Endpoint: "AssignWorkOrdersToUserAsync",
                Method: "PUT"
            },
            ChangeWorkOrderState: {
                Controller: "WorkOrder",
                Endpoint: "ChangeWorkOrderStateAsync",
                Method: "PUT"
            },
            CreateWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "CreateWorkOrderCalculationAreaGroupAsync",
                Method: "POST"
            },
            DeleteWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "DeleteWorkOrderCalculationAreaGroupAsync",
                Method: "DELETE"
            },
            AssignRowToWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "AssignRowToWorkOrderCalculationAreaGroupAsync",
                Method: "PUT"
            },
            CreateProject: {
                Controller: "Project",
                Endpoint: "CreateProjectAsync",
                Method: "POST"
            },
            DeleteProject: {
                Controller: "Project",
                Endpoint: "DeleteProjectAsync",
                Method: "DELETE"
            },
            DeleteProperty: {
                Controller: "Project",
                Endpoint: "DeletePropertyAsync",
                Method: "DELETE"
            },
            ImportProperties: {
                Controller: "Project",
                Endpoint: "ImportPropertiesAsync",
                Method: "POST"
            },
            BulkCreateOriginalFiles: {
                Controller: "Project",
                Endpoint: "BulkCreateOriginalFilesAsync",
                Method: "POST"
            },
            DeleteOriginalFile: {
                Controller: "Project",
                Endpoint: "DeleteOriginalFileAsync",
                Method: "DELETE"
            },
            GetOriginalFilesByProjectIDWorkGroupTypeID: {
                Controller: "Project",
                Endpoint: "GetOriginalFilesByProjectIDWorkGroupTypeIDAsync",
                Method: "GET"
            },
            ImportTakeoffWorkOrderRows: {
                Controller: "WorkOrder",
                Endpoint: "ImportTakeoffWorkOrderRowsAsync",
                Method: "POST"
            },
            GetImportPropertiesExcelTemplate: {
                Controller: "Project",
                Endpoint: "GetImportPropertiesExcelTemplateAsync",
                Method: "GET"
            },
            GetImportTakeoffRowsExcelTemplate: {
                Controller: "WorkOrder",
                Endpoint: "GetImportTakeoffRowsExcelTemplateAsync",
                Method: "GET"
            },
            GetNonCompletedUserWorkOrdersExcelTemplate: {
                Controller: "WorkOrder",
                Endpoint: "GetNonCompletedUserWorkOrdersExcelTemplateAsync",
                Method: "GET"
            },
            GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeIDAsync",
                Method: "GET"
            },
            GetWorkOrderCalculationAreaGroupsByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderCalculationAreaGroupsByWorkOrderIDAsync",
                Method: "GET"
            },
            GetPricingLibraries: {
                Controller: "PricingLibrary",
                Endpoint: "GetPricingLibrariesAsync",
                Method: "GET"
            },
            QueryPricingsByLibraryID: {
                Controller: "PricingLibrary",
                Endpoint: "QueryPricingsByLibraryIDAsync",
                Method: "GET"
            },
            DeletePricingLibrary: {
                Controller: "PricingLibrary",
                Endpoint: "DeletePricingLibraryByIDAsync",
                Method: "DELETE"
            },
            CreatePricingLibrary: {
                Controller: "PricingLibrary",
                Endpoint: "CreatePricingLibraryAsync",
                Method: "POST"
            },
            GetPricingByID: {
                Controller: "PricingLibrary",
                Endpoint: "GetPricingByIDAsync",
                Method: "GET"
            },
            CreateCustomPricing: {
                Controller: "PricingLibrary",
                Endpoint: "CreateCustomPricingAsync",
                Method: "POST"
            },
            UpdateCustomPricing: {
                Controller: "PricingLibrary",
                Endpoint: "UpdateCustomPricingAsync",
                Method: "POST"
            },
            DeleteCustomPricing: {
                Controller: "PricingLibrary",
                Endpoint: "DeleteCustomPricingAsync",
                Method: "DELETE"
            },
            CreateCalculationAreaGroupPricing: {
                Controller: "WorkOrder",
                Endpoint: "CreateCalculationAreaGroupPricingAsync",
                Method: "POST"
            },
            DeleteCalculationAreaGroupPricingByID: {
                Controller: "WorkOrder",
                Endpoint: "DeleteCalculationAreaGroupPricingByIDAsync",
                Method: "DELETE"
            },
            GetProformaInvoices: {
                Controller: "ProformaInvoice",
                Endpoint: "GetProformaInvoicesAsync",
                Method: "GET"
            },
            DeleteProformaInvoice: {
                Controller: "ProformaInvoice",
                Endpoint: "DeleteProformaInvoiceAsync",
                Method: "DELETE"
            },
            CreateProformaInvoice: {
                Controller: "ProformaInvoice",
                Endpoint: "CreateProformaInvoiceAsync",
                Method: "POST"
            },
            GetProformaInvoiceDetailByID: {
                Controller: "ProformaInvoice",
                Endpoint: "GetProformaInvoiceDetailByIDAsync",
                Method: "GET"
            },
            DeleteProformaInvoicePrice: {
                Controller: "ProformaInvoice",
                Endpoint: "DeleteProformaInvoicePriceAsync",
                Method: "DELETE"
            },
            CreateProformaInvoicePrice: {
                Controller: "ProformaInvoice",
                Endpoint: "CreateProformaInvoicePriceAsync",
                Method: "POST"
            },
            CopyTakeoffWorkOrderData: {
                Controller: "WorkOrder",
                Endpoint: "CopyTakeoffWorkOrderDataAsync",
                Method: "POST"
            },
            GetWorkOrderDrawingsByProjectIDWorkTypeID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderDrawingsByProjectIDWorkTypeIDAsync",
                Method: "GET"
            },
            ProcessDrawingWorkOrder: {
                Controller: "WorkOrder",
                Endpoint: "ProcessDrawingWorkOrderAsync",
                Method: "POST"
            },
            ResetDrawingWorkOrder: {
                Controller: "WorkOrder",
                Endpoint: "ResetDrawingWorkOrderAsync",
                Method: "POST"
            },
        }

        const copyTakeoffWorkOrderData = async (input) => {
            const endpoint = endpoints.CopyTakeoffWorkOrderData;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        }

        const bulkCreateOriginalFiles = async (input) => {
            const endpoint = endpoints.BulkCreateOriginalFiles;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        }

        const processDrawingWorkOrder = async (input) => {
            const endpoint = endpoints.ProcessDrawingWorkOrder;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        }

        const resetDrawingWorkOrder = async (workOrderID) => {
            const input = { workOrderID: workOrderID };
            const endpoint = endpoints.ResetDrawingWorkOrder;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteOriginalFile = async (id) => {
            const endpoint = endpoints.DeleteOriginalFile;
            const input = { id: id.toString() };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        }

        const fetchOriginalFilesByProjectIDWorkGroupTypeID = async (projectID, workGroupTypeID) => {
            const endpoint = endpoints.GetOriginalFilesByProjectIDWorkGroupTypeID;
            const params = { projectID: projectID, workGroupTypeID: workGroupTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        }

        const createProformaInvoicePrice = async (input) => {
            const endpoint = endpoints.CreateProformaInvoicePrice;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProformaInvoicePrice = async (input) => {
            const endpoint = endpoints.DeleteProformaInvoicePrice;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const downloadTenantFileAsBase64ByBlobID = async (blobID) => {
            const endpoint = endpoints.DownloadTenantFileAsBase64ByBlobID;
            const params = { blobID: blobID.toString() };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        }

        const fetchProformaInvoiceDetailByID = async (proformaInvoiceID) => {
            const endpoint = endpoints.GetProformaInvoiceDetailByID;
            const params = { proformaInvoiceID: proformaInvoiceID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        }

        const createProformaInvoice = async (input) => {
            const endpoint = endpoints.CreateProformaInvoice;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProformaInvoice = async (input) => {
            const endpoint = endpoints.DeleteProformaInvoice;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const fetchProformaInvoices = async () => {
            const endpoint = endpoints.GetProformaInvoices;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        }

        const deleteCalculationAreaGroupPricingByID = async (input) => {
            const endpoint = endpoints.DeleteCalculationAreaGroupPricingByID;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createCalculationAreaGroupPricing = async (input) => {
            const endpoint = endpoints.CreateCalculationAreaGroupPricing;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteCustomPricing = async (input) => {
            const endpoint = endpoints.DeleteCustomPricing;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const updateCustomPricing = async (input) => {
            const endpoint = endpoints.UpdateCustomPricing;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createCustomPricing = async (input) => {
            const endpoint = endpoints.CreateCustomPricing;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const fetchPricingByID = async (pricingLibraryID, pricingID) => {
            const endpoint = endpoints.GetPricingByID;
            const params = { pricingLibraryID: pricingLibraryID, pricingID: pricingID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderDrawingsByProjectIDWorkTypeID = async (projectID, workTypeID) => {
            const endpoint = endpoints.GetWorkOrderDrawingsByProjectIDWorkTypeID;
            const params = { projectID: projectID, workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const createPricingLibrary = async (input) => {
            const endpoint = endpoints.CreatePricingLibrary;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deletePricingLibrary = async (pricingLibraryID) => {
            const endpoint = endpoints.DeletePricingLibrary;
            const input = { pricingLibraryID: pricingLibraryID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        }

        const queryPricingsByLibraryID = async (args) => {
            const pricingLibraryID = args["pricingLibraryID"].toString();
            const validFrom = args["validFrom"]?.toString();
            const validTo = args["validTo"]?.toString();
            const query = args["query"];

            if (!pricingLibraryID || !query) {
                alert("missing parameter");
            }

            const endpoint = endpoints.QueryPricingsByLibraryID;
            const params = { dqb: query, pricingLibraryID: pricingLibraryID, validFrom: validFrom, validTo: validTo };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        }

        const fetchLibraries = async () => {
            const endpoint = endpoints.GetPricingLibraries;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchWorkTypeNamesByWorkGroupTypeID = async (workGroupTypeID) => {
            const endpoint = endpoints.GetWorkTypeNamesByWorkGroupTypeID;
            const params = { workGroupTypeID: workGroupTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchNextPrevAssignableWorkOrderIDByWorkOrderID = async (workOrderID, nextPrevious) => {
            const endpoint = endpoints.GetNextPrevAssignableWorkOrderIDByWorkOrderID;
            const params = { workOrderID: workOrderID, nextPrevious: nextPrevious };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkTypeByID = async (workTypeID) => {
            const endpoint = endpoints.GetWorkTypeByID;
            const params = { workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProppertyTreeByPropertyID = async (propertyID) => {
            const endpoint = endpoints.GetPropertyTreeByPropertyID;
            const params = { ChildPropertyID: propertyID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderByID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderByID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProjectPropertiesByProjectID = async (projectID, workTypeID) => {
            const endpoint = endpoints.GetProjectPropertiesByProjectID;
            let params = {};
            if (workTypeID) {
                params = { projectID: projectID, workTypeID: workTypeID };
            } else {
                params = { projectID: projectID };
            }

            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProjects = async () => {
            const endpoint = endpoints.GetProjects;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const upsertRow = async (input) => {
            const endpoint = endpoints.UpsertRow;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.CreateWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.DeleteWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const assignRowToWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.AssignRowToWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteRow = async (input) => {
            const endpoint = endpoints.DeleteRow;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createProject = async (input) => {
            const endpoint = endpoints.CreateProject;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProject = async (input) => {
            const endpoint = endpoints.DeleteProject;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProperty = async (input) => {
            const endpoint = endpoints.DeleteProperty;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const importProperties = async (input) => {
            const endpoint = endpoints.ImportProperties;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const importTakeoffWorkOrderRows = async (input) => {
            const endpoint = endpoints.ImportTakeoffWorkOrderRows;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const assignWorkOrdersToUser = async (input) => {
            const endpoint = endpoints.AssignWorkOrdersToUser;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const changeWorkOrderState = async (input) => {
            const endpoint = endpoints.ChangeWorkOrderState;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const fetchScreenTypeColorByID = async (id) => {
            const endpoint = endpoints.GetScreenTypeColorByID;
            const params = { id: id };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderStateByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderStateByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderRowsByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderRowsByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchRowTypesByScreenTypeID = async (screenTypeID) => {
            const endpoint = endpoints.GetRowTypesByScreenTypeID;
            const params = { screenTypeID: screenTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaTypeIDsByWorkTypeID = async (workTypeID) => {
            const endpoint = endpoints.GetCalculationAreaTypeIDsByWorkTypeID;
            const params = { workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaTypeByID = async (calculationAreaTypeID) => {
            const endpoint = endpoints.GetCalculationAreaTypeByID;
            const params = { calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaResultByWorkOrderIDCalculationAreaTypeID = async (workOrderID, calculationAreaTypeID) => {
            const endpoint = endpoints.GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeID;
            const params = { workOrderID: workOrderID, calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaGroupPricingsByCalculationAreaGroupID = async (calculationAreaGroupID) => {
            const endpoint = endpoints.GetCalculationAreaGroupPricingsByCalculationAreaGroupID;
            const params = { calculationAreaGroupID: calculationAreaGroupID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkGroupTypesByTakeoffTemplateID = async (takeoffTemplateID) => {
            const endpoint = endpoints.GetWorkGroupTypesByTakeoffTemplateID;
            const params = { takeoffTemplateID: takeoffTemplateID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchTenantTakeoffTemplates = async () => {
            const endpoint = endpoints.GetTenantTakeoffTemplates;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchTenantReportTemplates = async () => {
            const endpoint = endpoints.GetTenantReportTemplates;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchCompletedUserWorkOrdersByWorkTypeIDProjectID = async (workTypeID, projectID, type) => {
            const endpoint = endpoints.GetCompletedUserWorkOrdersByWorkTypeIDProjectID;
            const params = { workTypeID: workTypeID, projectID: projectID, workOrderType: type };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchNonCompletedUserWorkOrdersByWorkTypeIDProjectID = async (workTypeID, projectID, type) => {
            const endpoint = endpoints.GetNonCompletedUserWorkOrdersByWorkTypeIDProjectID;
            const params = { workTypeID: workTypeID, projectID: projectID, workOrderType: type };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrdersByWorkTypeIDProjectID = async (workTypeID, projectID, type) => {
            const endpoint = endpoints.GetWorkOrdersByWorkTypeIDProjectID;
            const params = { workTypeID: workTypeID, projectID: projectID, workOrderType: type };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchAllTenantUsers = async () => {
            const endpoint = endpoints.GetAllTenantUsers;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchImportPropertiesExcelTemplate = async (projectID) => {
            const endpoint = endpoints.GetImportPropertiesExcelTemplate;
            const params = { projectID: projectID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchNonCompletedUserWorkOrdersExcelTemplate = async (projectID, workTypeID, workOrderType) => {
            const endpoint = endpoints.GetNonCompletedUserWorkOrdersExcelTemplate;
            const params = { projectID: projectID, workTypeID: workTypeID, workOrderType: workOrderType };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        }

        const fetchImportTakeoffRowsExcelTemplate = async (projectID, workTypeID) => {
            const endpoint = endpoints.GetImportTakeoffRowsExcelTemplate;
            const params = { projectID: projectID, workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID = async (workOrderID, calculationAreaTypeID) => {
            const endpoint = endpoints.GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID;
            const params = { workOrderID: workOrderID, calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderCalculationAreaGroupsByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderCalculationAreaGroupsByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        return {
            fetchWorkTypeByID,
            fetchProppertyTreeByPropertyID,
            fetchWorkOrderByID,
            fetchProjectPropertiesByProjectID,
            upsertRow,
            deleteRow,
            fetchProjects,
            fetchWorkTypeNamesByWorkGroupTypeID,
            fetchScreenTypeColorByID,
            fetchWorkOrderStateByWorkOrderID,
            fetchNextPrevAssignableWorkOrderIDByWorkOrderID,
            assignWorkOrdersToUser,
            fetchWorkOrderRowsByWorkOrderID,
            fetchRowTypesByScreenTypeID,
            fetchCalculationAreaTypeIDsByWorkTypeID,
            fetchCalculationAreaTypeByID,
            fetchCalculationAreaResultByWorkOrderIDCalculationAreaTypeID,
            fetchCalculationAreaGroupPricingsByCalculationAreaGroupID,
            fetchWorkGroupTypesByTakeoffTemplateID,
            fetchTenantTakeoffTemplates,
            createProject,
            importProperties,
            fetchWorkOrdersByWorkTypeIDProjectID,
            fetchAllTenantUsers,
            fetchImportPropertiesExcelTemplate,
            fetchWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID,
            createWorkOrderCalculationAreaGroup,
            deleteWorkOrderCalculationAreaGroup,
            assignRowToWorkOrderCalculationAreaGroup,
            fetchWorkOrderCalculationAreaGroupsByWorkOrderID,
            deleteProject,
            deleteProperty,
            changeWorkOrderState,
            fetchLibraries,
            queryPricingsByLibraryID,
            deletePricingLibrary,
            createPricingLibrary,
            fetchPricingByID,
            createCustomPricing,
            updateCustomPricing,
            deleteCustomPricing,
            createCalculationAreaGroupPricing,
            deleteCalculationAreaGroupPricingByID,
            fetchProformaInvoices,
            createProformaInvoice,
            deleteProformaInvoice,
            fetchProformaInvoiceDetailByID,
            createProformaInvoicePrice,
            deleteProformaInvoicePrice,
            fetchNonCompletedUserWorkOrdersByWorkTypeIDProjectID,
            fetchCompletedUserWorkOrdersByWorkTypeIDProjectID,
            copyTakeoffWorkOrderData,
            fetchImportTakeoffRowsExcelTemplate,
            importTakeoffWorkOrderRows,
            fetchNonCompletedUserWorkOrdersExcelTemplate,
            downloadTenantFileAsBase64ByBlobID,
            bulkCreateOriginalFiles,
            fetchOriginalFilesByProjectIDWorkGroupTypeID,
            deleteOriginalFile,
            fetchWorkOrderDrawingsByProjectIDWorkTypeID,
            processDrawingWorkOrder,
            resetDrawingWorkOrder,
            fetchTenantReportTemplates
        };
    }, [callApi]);

    return approxAPIClient;
};

export default ApproxAPIClient;