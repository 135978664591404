import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Stack,
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Button
} from '@mui/material';
import ApproxAPIClient from '../../../ApproxAPIClient';
import { PricingUnitValTypeDict } from '../../../constants/PricingUnitType';
import CreateProformaInvoicePriceDialog from '../components/CreateProformaInvoicePriceDialog';
import { v4 as guid } from 'uuid';

export default function ProformaInvoiceDetail({ proformaInvoiceID, handleProformaPriceSelect }) {
    const approxAPI = ApproxAPIClient();
    const [proformaInvoice, setProformaInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [proformaInvoiceRenderID, setProformaInvoiceRenderID] = useState(guid());
    const [createProformaInvoicePriceIsOpen, setCreateProformaInvoicePriceIsOpen] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState();

    const handleRefreshProformaInvoice = () => {
        setProformaInvoiceRenderID(guid());
    }

    useEffect(() => {
        if (!proformaInvoiceID) {
            setProformaInvoice(undefined);
            return;
        };

        const fetchProformaInvoiceDetail = async () => {
            setIsLoading(true);
            try {
                const response = await approxAPI.fetchProformaInvoiceDetailByID(proformaInvoiceID);
                if (response.isSuccessful) {
                    setProformaInvoice(response.data);
                } else {
                    alert(response.error);
                }
            } catch (error) {
                console.error('Error fetching pricing:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProformaInvoiceDetail();
    }, [proformaInvoiceID, approxAPI, proformaInvoiceRenderID]);

    const handlePricingClick = (selected) => {
        if (handleProformaPriceSelect) {
            handleProformaPriceSelect(selected);
        }
        setSelectedPrice(selected);
    }

    const handleProformaInvoicePricingDelete = async (proformaInvoicePriceID) => {
        setIsLoading(true);
        try {
            const input = {
                proformaInvoicePriceID: proformaInvoicePriceID
            }

            await approxAPI.deleteProformaInvoicePrice(input).then((response) => {
                if (response.isSuccessful === true) {
                    handleRefreshProformaInvoice();
                } else {
                    alert(response.error);
                }
            })
        } catch (error) {
            alert(error);
        }

        setIsLoading(false);
    }

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CreateProformaInvoicePriceDialog
                proformaInvoiceID={proformaInvoiceID}
                createProformaInvoicePriceIsOpen={createProformaInvoicePriceIsOpen}
                setCreateProformaInvoicePriceIsOpen={setCreateProformaInvoicePriceIsOpen}
                handleCreateProformaInvoicePriceSuccess={handleRefreshProformaInvoice}
            />
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                    Proforma Invoice Detail
                </Typography>
                <Button disabled={handleProformaPriceSelect} onClick={() => setCreateProformaInvoicePriceIsOpen(true)}>Add Price</Button>
            </Stack>
            {!proformaInvoiceID ? (
                <Typography color="text.secondary" textAlign="center">
                    Please select a proforma invoice
                </Typography>
            ) : isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : proformaInvoice ? (
                <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell size='medium' align="left">Company Name</TableCell>
                                <TableCell size='small' align="center">Valid From</TableCell>
                                <TableCell size='small' align="center">Valid To</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell size='medium' align="left">{proformaInvoice.companyName}</TableCell>
                                <TableCell size='small' align="center">{proformaInvoice.validFrom}</TableCell>
                                <TableCell size='small' align="center">{proformaInvoice.validTo}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={3} sx={{ padding: 0 }}>
                                    <Collapse in timeout="auto" unmountOnExit>
                                        <Table size="small" aria-label="sub-items">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Code</TableCell>
                                                    <TableCell align="left">Definition</TableCell>
                                                    <TableCell align="center">Unit</TableCell>
                                                    <TableCell align="center">Price</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(proformaInvoice.proformaInvoicePrices ?? []).map((subItem, index) => (
                                                    <React.Fragment key={index}>
                                                        <TableRow selected={selectedPrice?.id === subItem.id} onClick={() => handlePricingClick(subItem)}>
                                                            <TableCell align="center">{subItem.code}</TableCell>
                                                            <TableCell align="left">{subItem.definition}</TableCell>
                                                            <TableCell align="center">{PricingUnitValTypeDict[subItem.pricingUnit]}</TableCell>
                                                            <TableCell align="center">{subItem.price.toFixed(2)}</TableCell>
                                                            <TableCell><Button disabled={handleProformaPriceSelect} onClick={() => handleProformaInvoicePricingDelete(subItem.id)}>Remove</Button></TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                    <Typography color="text.secondary" textAlign="center">
                        No data available.
                    </Typography>
                </Stack>
            )}
        </Paper>
    );
}
