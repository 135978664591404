import React, { useState, useEffect, useCallback } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom'
import EditOffIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import WorkOrderType from '../../../constants/WorkOrderType'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalculateIcon from '@mui/icons-material/Calculate';
import DrawIcon from '@mui/icons-material/Draw';
import WorkOrderState from '../../../constants/WorkOrderState';
import UploadFilesDialog from '../../common/components/UploadFilesDialog';
import { v4 as guid } from 'uuid';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImportTakeoffDialog from './ImportTakeoffDialog';
import { fileToBase64 } from '../../common/helpers/FileToBase64Helper';

function WorkOrderList({ workTypeID, hidden, propsWorkOrderID, workOrderListRenderID, setWorkOrderListRenderID, setCompletedWorkOrderListRenderID, refreshWorkOrder }) {
  const approxAPIClient = ApproxAPIClient();
  const [workOrders, setWorkOrders] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("projectID");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedWorkOrderType, setSelectedWorkOrderType] = useState();
  const [isUploadDrawingButtonActive, setIsUploadDrawingButtonActive] = useState(false);
  const [uploadDrawingsIsOpen, setUploadDrawingsIsOpen] = useState(false);
  const [drawingListRenderID, setDrawingListRenderID] = useState(guid())
  const [drawings, setDrawings] = useState(undefined);
  const [selectedWorkOrderIDs, setSelectedWorkOrderIDs] = useState([]);
  const [importTakeoffIsOpen, setImportTakeoffIsOpen] = useState(false);

  const handleImportTakeoffSuccess = () => {
    setWorkOrderListRenderID(guid());
  }

  const handleImportTakeoffClickOpen = () => {
    setImportTakeoffIsOpen(true);
  };

  const handleToggleCheckBox = (workOrderID) => () => {
    const index = selectedWorkOrderIDs.indexOf(workOrderID);
    if (index === -1) {
      setSelectedWorkOrderIDs([workOrderID, ...selectedWorkOrderIDs]);
    } else {
      const otherWorkOrderIDs = [...selectedWorkOrderIDs].filter(x => x !== workOrderID);
      setSelectedWorkOrderIDs([...otherWorkOrderIDs]);
    }
  };

  const handleSelectAll = () => {
    let userWorkOrders = workOrders.filter(x => !x.isReadOnly);
    if (selectedWorkOrderIDs.length < userWorkOrders.length) {
      const allWorkOrderIDs = userWorkOrders.map(x => x.id);
      setSelectedWorkOrderIDs(allWorkOrderIDs);
    } else {
      setSelectedWorkOrderIDs([]);
    }
  }

  const handleCompleteClick = async () => {
    await changeWorkOrderState(WorkOrderState.Completed, selectedWorkOrderIDs);
  }

  const handleUploadDrawingsSuccess = () => {
    setDrawingListRenderID(guid());
    setIsUploadDrawingButtonActive(false);
    setWorkOrderListRenderID(guid());
    setSelectedWorkOrder(undefined);
    setSelectedWorkOrderIDs([]);
    setCompletedWorkOrderListRenderID(guid());
    if (refreshWorkOrder) {
      refreshWorkOrder();
    }
  }

  const changeWorkOrderState = async (state, workOrderIDs) => {
    setIsLoading(true);
    const input = {
      workOrderIDs: workOrderIDs,
      workOrderState: state
    }
    await approxAPIClient.changeWorkOrderState(input);
    setWorkOrderListRenderID(guid());
    setSelectedWorkOrder(undefined);
    setSelectedWorkOrderIDs([]);
    setIsLoading(false);
    setCompletedWorkOrderListRenderID(guid());
    if (refreshWorkOrder) {
      refreshWorkOrder();
    }
  }

  const handleTabSelect = (val) => {
    if (isLoading) {
      return;
    }
    setSelectedTabIndex(val);
  }

  const handleUploadDrawingClick = () => {
    setUploadDrawingsIsOpen(true);
  }

  const handleBackToProgress = async () => {
    await changeWorkOrderState(WorkOrderState.Started, selectedWorkOrderIDs);
  }

  const handleResetDrawingOrder = async () => {
    setIsLoading(true);
    await approxAPIClient.resetDrawingWorkOrder(selectedWorkOrder?.id);
    setDrawingListRenderID(guid());
    setWorkOrderListRenderID(guid());
    setSelectedWorkOrder(undefined);
    setSelectedWorkOrderIDs([]);
    setIsLoading(false);
    setCompletedWorkOrderListRenderID(guid());
    if (refreshWorkOrder) {
      refreshWorkOrder();
    }
    setIsLoading(false);
  }

  const getStateName = (stateVal) => {
    var keys = Object.keys(WorkOrderState).sort(function (a, b) {
      return WorkOrderState[a] - WorkOrderState[b];
    });

    return keys[stateVal];
  }

  const startTakeoff = async () => {
    if (selectedWorkOrderType === WorkOrderType.Takeoff) {
      const queryParams = {
        workOrderID: selectedWorkOrder?.id,
        projectID: projectID
      };

      navigate(`/takeoff?${new URLSearchParams(queryParams).toString()}`);
    } else if (selectedWorkOrderType === WorkOrderType.Drawing) {
      if (selectedWorkOrder.isReadOnly) {
        alert("Work order is read only. Please assign work order to user");
        return;
      }
      setIsUploadDrawingButtonActive(true);
    }
  }

  const handleDownloadDrawing = async (drawing) => {
    setIsLoading(true);
    const response = await approxAPIClient.downloadTenantFileAsBase64ByBlobID(drawing.blobID);
    if (response.isSuccessful) {
      const a = document.createElement("a");
      a.href = `data:${response.data.contentType};base64,${response.data}`;
      a.download = `${drawing.name}.${drawing.extension}`;
      a.click();
    } else {
      alert(response.error);
    }
    setIsLoading(false);
  }

  const handleListItemClick = useCallback((e, node) => {
    setSelectedWorkOrder(node);
  }, []);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setSelectedWorkOrderType(WorkOrderType.Takeoff);
    } else if (selectedTabIndex === 1) {
      setSelectedWorkOrderType(WorkOrderType.Drawing);
    }
    setIsUploadDrawingButtonActive(false);
    setSelectedWorkOrder(undefined);
  }, [selectedTabIndex])

  useEffect(() => {
    if (!workTypeID) {
      return;
    }

    setIsLoading(true);
    setDrawings(undefined);
    approxAPIClient.fetchWorkOrderDrawingsByProjectIDWorkTypeID(projectID, workTypeID).then((response) => {
      if (response.isSuccessful) {
        setDrawings(response.data);
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    });

  }, [workTypeID, projectID, approxAPIClient, drawingListRenderID])

  useEffect(() => {
    if (!workTypeID || !projectID || !selectedWorkOrderType) {
      return;
    }

    setIsLoading(true);
    setWorkOrders(undefined);
    approxAPIClient.fetchWorkOrdersByWorkTypeIDProjectID(workTypeID, projectID, selectedWorkOrderType).then((response) => {
      if (response.isSuccessful === true) {
        setWorkOrders(response.data);
        if (propsWorkOrderID) {
          const selected = response.data.find(x => x.id === propsWorkOrderID);
          setSelectedWorkOrder(selected);
        } else {
          setSelectedWorkOrder(undefined);
        }
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    });

  }, [approxAPIClient, handleListItemClick, workTypeID, projectID, propsWorkOrderID, workOrderListRenderID, selectedWorkOrderType])

  return (
    <>
      {
        <UploadFilesDialog
          isOpen={uploadDrawingsIsOpen}
          setIsOpen={setUploadDrawingsIsOpen}
          isMultiple={false}
          handleFilesSet={async (files) => {
            setIsLoading(true);
            try {
              const promises = files.map(async (file) => {
                const input = {
                  workOrderID: selectedWorkOrder?.id,
                  file: {
                    name: file.name,
                    ext: file.ext,
                    base64File: await fileToBase64(file.buffer),
                  },
                };

                const res = await approxAPIClient.processDrawingWorkOrder(input);
                if (!res.isSuccessful) {
                  alert(res.error);
                }
              });

              // Wait for all promises to complete
              await Promise.all(promises);

              // Call success handler after all files are processed
              handleUploadDrawingsSuccess();
            } catch (error) {
              console.error("Error processing files:", error);
            } finally {
              setIsLoading(false);
            }
          }}
        />
      }
      {<ImportTakeoffDialog
        handleImportTakeoffSuccess={handleImportTakeoffSuccess}
        setImportTakeoffIsOpen={setImportTakeoffIsOpen}
        importTakeoffIsOpen={importTakeoffIsOpen}
        projectID={projectID}
        workTypeID={workTypeID}
      />}
      <Grid container spacing={1} hidden={hidden} style={{ height: '100%' }}>
        <Grid item xs={9} style={{ height: '100%' }}>
          <Paper style={{ height: '100%', width: '100%' }}>
            <Stack spacing={0} direction="row" sx={{ width: '100%', height: '100%' }} justifyContent='space-between'>
              <Tabs
                orientation="vertical"
                value={selectedTabIndex}
                onChange={(event, newValue) => handleTabSelect(newValue)}
                disabled={isLoading}
              >
                <Tab icon={<CalculateIcon fontSize='small' />} label="Takeoff" />
                <Tab icon={<DrawIcon fontSize='small' />} label="Drawing" />
              </Tabs>
              <List
                component="nav"
                aria-label="work group type list"
                style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', width: '100%' }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                      <Typography sx={{ height: "100%", lineHeight: "200%", width: '80%' }} color="text.secondary" textAlign="left">
                        Work Orders
                      </Typography>
                      {selectedWorkOrderType === WorkOrderType.Takeoff ?
                        <IconButton color='success' variant='contained' disabled={isLoading} onClick={handleImportTakeoffClickOpen}>
                          <UploadFileIcon />
                        </IconButton> : <></>}
                      {workOrders && selectedWorkOrderIDs && workOrders.length > 0 ? <Box
                        onClick={() => handleSelectAll()}>
                        {selectedWorkOrderType === WorkOrderType.Takeoff ?

                          <Checkbox
                            sx={{ height: "100%" }}
                            label='Select All'
                            disabled={workOrders.filter(x => !x.isReadOnly).length === 0}
                            checked={workOrders.filter(x => !x.isReadOnly).length !== 0 && selectedWorkOrderIDs.length === workOrders.filter(x => !x.isReadOnly).length}
                          /> : <></>}
                      </Box> : <></>}
                    </Stack>
                  </ListSubheader>
                }
              >
                {!workTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                  Please select a work type
                </Typography> :
                  !isLoading && workOrders && workOrders.length !== 0 ? workOrders.map(x => {

                    let parentList = [];
                    let parent = x.property.parent;

                    while (parent !== null) {
                      parentList.push(`${parent.code} ${parent.name}`);
                      parent = parent.parent;
                    }

                    parentList.reverse();
                    return (
                      <ListItemButton
                        selected={x.id === selectedWorkOrder?.id}
                        onClick={(e) => handleListItemClick(e, x)}
                        key={x.id}
                      >
                        <ListItemIcon>
                          {x.isReadOnly ? <EditOffIcon /> : <EditIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={`${x.code} ${x.property.name}`}
                          primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '50px',
                            mb: '2px',
                            color: 'black'
                          }}
                          secondary={parentList.join('/')}
                          secondaryTypographyProps={{
                            fontWeight: 'medium',
                            mb: '2px',
                            textAlign: 'left'
                          }}
                        />
                        <ListItemText
                          primary={getStateName(x.state)}
                          primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '50px',
                            mb: '2px',
                            color: 'black'
                          }}
                        />
                        {selectedWorkOrderType === WorkOrderType.Takeoff ?
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              onClick={handleToggleCheckBox(x.id)}
                              checked={selectedWorkOrderIDs.indexOf(x.id) !== -1}
                              disabled={x.isReadOnly}
                              disableRipple
                            />
                          </ListItemIcon>
                          : <></>}

                      </ListItemButton>)
                  }

                  ) : !isLoading && workOrders && workOrders.length === 0 ? <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                    There is no work order!
                  </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>}
              </List>
            </Stack>
          </Paper>
        </Grid >
        <Grid item xs={3}>
          <Box style={{ height: '30%' }}>
            {selectedWorkOrderType === WorkOrderType.Takeoff ? <>
              {
                selectedWorkOrderIDs.length > 0 ? <>
                  <Button color='success' variant='contained' fullWidth disabled={isLoading || selectedWorkOrderIDs.length === 0} style={{ marginBottom: 5 }} onClick={handleCompleteClick}>Complete</Button>
                  <Button color='error' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrderIDs.length === 0} style={{ marginBottom: 5 }} onClick={handleBackToProgress}>Back To Progress</Button>
                </> :
                  <Button color='info' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrder?.id === undefined} style={{ marginBottom: 5 }} onClick={startTakeoff}>Start Takeoff</Button>
              }
            </> :
              <>
                {selectedWorkOrder?.state === WorkOrderState.Completed ? <Button color='error' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrder?.id === undefined || selectedWorkOrder.isReadOnly === true} style={{ marginBottom: 5 }} onClick={handleResetDrawingOrder}>Reset</Button> : <>
                  <Button color='info' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrder?.id === undefined || isUploadDrawingButtonActive === true} style={{ marginBottom: 5 }} onClick={startTakeoff}>Start Takeoff</Button>
                  {isUploadDrawingButtonActive ? <>
                    <Button color='success' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrder?.id === undefined} style={{ marginBottom: 5 }} onClick={handleUploadDrawingClick}>Upload Drawing</Button>
                    <Button color='warning' variant='contained' fullWidth disabled={isLoading === true || selectedWorkOrder?.id === undefined} style={{ marginBottom: 5 }} onClick={() => setIsUploadDrawingButtonActive(false)}>Cancel</Button>
                  </> : <></>}

                </>}
              </>}
          </Box>
          <Paper style={{ height: '70%' }}>
            <List
              component="nav"
              aria-label="original files list"
              style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                    <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                      Work Type Drawings
                    </Typography>
                  </Stack>
                </ListSubheader>
              }
            >
              {!workTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                Please select a work type
              </Typography> :
                !isLoading && drawings && drawings.length > 0 ? drawings.map(drawing =>
                  <ListItem
                    key={drawing.id}
                  >
                    <ListItemText
                      primary={`${drawing.name}.${drawing.extension}`}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: 'medium',
                        lineHeight: '30px',
                        mb: '2px',
                        color: 'black'
                      }}
                    />
                    <IconButton onClick={() => handleDownloadDrawing(drawing)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItem>
                ) : !isLoading && drawings && drawings.length === 0 ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                  There is no drawing
                </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>}
            </List>
          </Paper>
        </Grid>
      </Grid >
    </>
  );
}

export default WorkOrderList;
