import React, { useMemo, useState } from 'react';
import ProformaInvoiceList from '../proforma/components/ProformaInvoiceList';
import ProformaInvoiceDetail from '../proforma/components/ProformaInvoiceDetail';
import { v4 as guid } from 'uuid';

import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle

} from '@mui/material';

export default function SelectProformaModal({ open, setIsOpen, handleProformaPriceSelect, selectProformaModalKey }) {
    const [selectedProformaInvoice, setSelectedProformaInvoice] = useState();
    const [proformaInvoiceListRenderID, setProformaInvoiceListRenderID] = useState(guid());
    const [selectedProformaInvoicePrice, setSelectedProformaInvoicePrice] = useState();

    const refreshProformaInvoiceList = useMemo(() => {
        return () => setProformaInvoiceListRenderID(guid());
    }, [setProformaInvoiceListRenderID])

    const handleProformaInvoiceSelect = (proformaInvoice) => {
        setSelectedProformaInvoice(proformaInvoice);
    }

    const handleResetProformaInvoiceSelect = () => {
        setSelectedProformaInvoice(undefined);
        setSelectedProformaInvoicePrice(undefined);
    }

    const handleCancel = () => {
        handleResetProformaInvoiceSelect();
        setIsOpen(false);
    };

    const handleSave = () => {
        handleProformaPriceSelect(selectedProformaInvoice, selectedProformaInvoicePrice);
        setSelectedProformaInvoicePrice(undefined);
        setIsOpen(false);
    };

    const handleProformaPriceSelectInternal = (selected) => {
        setSelectedProformaInvoicePrice(selected);
    }

    return (
        <Dialog key={selectProformaModalKey} open={open} onClose={handleCancel} maxWidth='lg' fullWidth>
            <DialogTitle>
                Select Pricing
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ height: '700px' }}>
                    <Grid item xs={4}>
                        <ProformaInvoiceList
                            handleResetProformaInvoiceSelect={handleResetProformaInvoiceSelect}
                            handleProformaInvoiceSelect={handleProformaInvoiceSelect}
                            selectedProformaInvoice={selectedProformaInvoice}
                            proformaInvoiceListRenderID={proformaInvoiceListRenderID}
                            refreshProformaInvoiceList={refreshProformaInvoiceList} />
                    </Grid>
                    <Grid item xs={8} style={{ height: '100%' }}>
                        <ProformaInvoiceDetail proformaInvoiceID={selectedProformaInvoice?.id} handleProformaPriceSelect={handleProformaPriceSelectInternal} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={!selectedProformaInvoicePrice}>
                    Select
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}