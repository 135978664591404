import React, { useMemo, useState } from 'react';
import Layout from '../common/components/Layout';
import Grid from '@mui/material/Grid';
import ProformaInvoiceViewer from './components/ProformaInvoiceViewer';
import ProformaInvoiceList from './components/ProformaInvoiceList';
import ProformaInvoiceDetail from './components/ProformaInvoiceDetail';
import { v4 as guid } from 'uuid';

export default function ProformaPage() {

    const [selectedProformaInvoice, setSelectedProformaInvoice] = useState();
    const [proformaInvoiceListRenderID, setProformaInvoiceListRenderID] = useState(guid())

    const refreshProformaInvoiceList = useMemo(() => {
        return () => setProformaInvoiceListRenderID(guid());
    }, [setProformaInvoiceListRenderID])

    const handleProformaInvoiceSelect = (proformaInvoice) => {
        setSelectedProformaInvoice(proformaInvoice);
    }

    const handleResetProformaInvoiceSelect = () => {
        setSelectedProformaInvoice(undefined);
    }
    return (
        <Layout>
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={3}>
                    <ProformaInvoiceList
                        handleResetProformaInvoiceSelect={handleResetProformaInvoiceSelect}
                        handleProformaInvoiceSelect={handleProformaInvoiceSelect}
                        selectedProformaInvoice={selectedProformaInvoice}
                        proformaInvoiceListRenderID={proformaInvoiceListRenderID}
                        refreshProformaInvoiceList={refreshProformaInvoiceList} />
                </Grid>
                <Grid item xs={4} style={{ height: '100%' }}>
                    <ProformaInvoiceViewer selectedProformaInvoice={selectedProformaInvoice} />
                </Grid>
                <Grid item xs={5} style={{ height: '100%' }}>
                    <ProformaInvoiceDetail proformaInvoiceID={selectedProformaInvoice?.id} />
                </Grid>
            </Grid>
        </Layout>
    );
};