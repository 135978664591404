import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import InputLabel from '@mui/material/InputLabel';
import { PricingUnitValTypeDict } from '../../../constants/PricingUnitType';


export default function CreateProformaInvoicePriceDialog({ proformaInvoiceID, createProformaInvoicePriceIsOpen, setCreateProformaInvoicePriceIsOpen, handleCreateProformaInvoicePriceSuccess }) {
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateProformaInvoicePriceClose = () => {
        setCreateProformaInvoicePriceIsOpen(false);
    };

    return <Dialog
        open={createProformaInvoicePriceIsOpen}
        onClose={handleCreateProformaInvoicePriceClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                setIsLoading(true);
                event.preventDefault();
                const input = {
                    ...formJson,
                    proformaInvoiceID: proformaInvoiceID
                }

                approxAPIClient.createProformaInvoicePrice(input).then((res) => {
                    if (res.isSuccessful === true) {
                        handleCreateProformaInvoicePriceSuccess();
                    } else {
                        alert(res.error);
                    }
                    setIsLoading(false);
                    handleCreateProformaInvoicePriceClose();
                })
            },
        }}
    >
        <DialogTitle>Create Proforma Invoice</DialogTitle>
        {
            !isLoading ? <>
                <DialogContent>
                    <InputLabel id="proformaInvoicePriceCode-label">Code</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="proformaInvoicePriceCode"
                        name="code"
                        type="text"
                        fullWidth
                        variant="standard"
                    />

                    <InputLabel id="proformaInvoicePriceDefinition-label">Definition</InputLabel>
                    <TextField
                        required
                        margin="dense"
                        id="proformaInvoicePriceDefinition"
                        name="definition"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <InputLabel id="proformaInvoicePriceUnit-label">Unit</InputLabel>
                    <Select
                        name="unit"
                        margin="dense"
                        id="proformaInvoicePriceUnit"
                        fullWidth
                        variant="standard"
                        required
                    >
                        {Object.entries(PricingUnitValTypeDict).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <InputLabel id="proformaInvoicePricePrice-label">Price</InputLabel>
                    <TextField
                        required
                        margin="dense"
                        id="proformaInvoicePricePrice"
                        name="price"
                        type="number"
                        fullWidth
                        variant="standard"
                        inputProps={{
                            maxLength: 13,
                            step: "0.01"
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateProformaInvoicePriceClose}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        }
    </Dialog>
}