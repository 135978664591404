import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField

} from '@mui/material';

export default function PricingScaleModal({ open, setIsOpen, handleSubmit, pricingScaleModalKey }) {

    const [scale, setScale] = useState(1.0);
    const handleCancel = () => {
        setScale(1.0);
        setIsOpen(false);
    };

    const handleSave = () => {
        handleSubmit(scale);
        setIsOpen(false);
        setScale(1.0);
    };

    return (
        <Dialog key={pricingScaleModalKey} open={open} onClose={handleCancel} maxWidth='lg' fullWidth>
            <DialogTitle>
                Set Pricing Scale
            </DialogTitle>
            <DialogContent>
                <TextField
                    key={0}
                    required
                    margin="dense"
                    label={"Pricing Scale"}
                    type="number"
                    fullWidth
                    value={scale}
                    onChange={(e) => setScale(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Submit
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}