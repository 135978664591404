import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Stack,
    Collapse,
    Table,
    Chip,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Button
} from '@mui/material';
import ApproxAPIClient from '../../../ApproxAPIClient';
import { PricingUnitValTypeDict } from '../../../constants/PricingUnitType';
import { PricingStateValTypeDict, PricingStateType } from '../../../constants/PricingStateType';
import { ProformaCalculationMethodValTypeDict } from '../../../constants/ProformaCalculationMethod';
import LibraryType from '../../../constants/LibraryType';
import PricingModal from './PricingModal';
import { v4 as guid } from 'uuid';
import { PricingType, PricingTypeValDict } from '../../../constants/PricingType';

export default function PricingDetail({ selectedPricingLibrary, selectedPricingID, handleRefreshPricingList, refreshLibraryList, handleResetPricingSelect }) {
    const approxAPI = ApproxAPIClient();
    const [pricing, setPricing] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
    const [pricingRenderID, setPricingRenderID] = useState(guid())

    const handleRefreshPricing = () => {
        setPricingRenderID(guid());
    }

    const handlePricingDelete = async () => {
        const input = {
            pricingID: selectedPricingID
        };

        setIsLoading(true);
        await approxAPI.deleteCustomPricing(input).then((res) => {
            if (res.isSuccessful !== true) {
                alert(res.error);
            } else {
                handleResetPricingSelect();
                handleRefreshPricingList();
            }
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!selectedPricingID || !selectedPricingLibrary) {
            setPricing(undefined);
            return;
        };

        const fetchPricing = async () => {
            setIsLoading(true);
            try {
                const response = await approxAPI.fetchPricingByID(selectedPricingLibrary?.id, selectedPricingID);
                if (response.isSuccessful) {
                    setPricing(response.data);
                } else {
                    alert(response.error);
                }
            } catch (error) {
                console.error('Error fetching pricing:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPricing();
    }, [selectedPricingID, selectedPricingLibrary, approxAPI, pricingRenderID]);

    const PricingRow = ({ row }) => (
        <>
            {/* Main Row */}
            <TableRow>
                <TableCell align="center">{row.code}</TableCell>
                <TableCell align="left">{row.definition}</TableCell>
                <TableCell align="center">{PricingUnitValTypeDict[row.pricingUnit]}</TableCell>
            </TableRow>

            {/* Description Row */}
            {row.description && (
                <TableRow>
                    <TableCell colSpan={3} sx={{ padding: 2, fontStyle: 'italic', backgroundColor: '#f9f9f9' }}>
                        {row.description}
                    </TableCell>
                </TableRow>
            )}

            {/* Child Items with Subtotals */}
            <TableRow>
                <TableCell colSpan={3} sx={{ padding: 0 }}>
                    <Collapse in timeout="auto" unmountOnExit>
                        {row.items && row.items.length > 0 ? (
                            <Table size="small" aria-label="sub-items">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Code</TableCell>
                                        <TableCell align="left">Definition</TableCell>
                                        <TableCell align="center">Unit</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Line Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((subItem, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell align="center">{subItem.code}</TableCell>
                                                <TableCell align="left">{subItem.definition}</TableCell>
                                                <TableCell align="center">{PricingUnitValTypeDict[subItem.pricingUnit]}</TableCell>
                                                <TableCell align="center">{subItem.amount.toFixed(2)}</TableCell>
                                                <TableCell align="center">{subItem.unitPrice.toFixed(2)}</TableCell>
                                                <TableCell align="center">{subItem.lineTotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                            {subItem.percentagePricing !== null && (
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell align="left" colSpan={4}>{`${subItem.percentagePricing.definition} (${subItem.percentagePricing.percentage})`}</TableCell>
                                                    <TableCell align="center">{subItem.percentagePricing.price.toFixed(2)}</TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}

                                    {/* Subtotal Row */}
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.rawUnitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Contractor Profit Percentage ({row.contractorProfitPercentage.toFixed(2)})
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.contractorProfitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Unit Price
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.unitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        ) : row.proformaInvoicePrices.length > 0 ? <>
                            <Table size="small" aria-label="sub-items">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Code</TableCell>
                                        <TableCell align="left">Company Name</TableCell>
                                        <TableCell align="left">Definition</TableCell>
                                        <TableCell align="center">Unit</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.proformaInvoicePrices.map((subItem, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell align="center">{subItem.code}</TableCell>
                                                <TableCell align="left">{subItem.companyName}</TableCell>
                                                <TableCell align="left">{subItem.definition}</TableCell>
                                                <TableCell align="center">{PricingUnitValTypeDict[subItem.pricingUnit]}</TableCell>
                                                <TableCell align="center">{subItem.price.toFixed(2)}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}

                                    {/* Subtotal Row */}
                                    <TableRow>
                                        <TableCell colSpan={2} align="right" sx={{ fontWeight: 'bold' }}>
                                            Proforma Calculation Method
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {ProformaCalculationMethodValTypeDict[row.proformaCalculationMethod]}
                                        </TableCell>
                                        <TableCell colSpan={1} align="right" sx={{ fontWeight: 'bold' }}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.rawUnitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} align="right" sx={{ fontWeight: 'bold' }}>
                                            Contractor Profit Percentage ({row.contractorProfitPercentage.toFixed(2)})
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.contractorProfitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} align="right" sx={{ fontWeight: 'bold' }}>
                                            Unit Price
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.unitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </> : (
                            <Table size="small" aria-label="sub-items">
                                <TableBody>
                                    {/* Subtotal Row */}
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.rawUnitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Contractor Profit Percentage ({row.contractorProfitPercentage.toFixed(2)})
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.contractorProfitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Unit Price
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.unitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <PricingModal
                open={isPricingModalOpen}
                setIsOpen={setIsPricingModalOpen}
                pricingData={pricing ? JSON.parse(JSON.stringify(pricing)) : undefined}
                pricingLibraryID={selectedPricingLibrary?.id}
                handleRefreshPricingList={handleRefreshPricingList}
                handleResetPricingSelect={handleResetPricingSelect}
                refreshLibraryList={refreshLibraryList}
                handleRefreshPricing={handleRefreshPricing}
            />

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h6" color="text.secondary" sx={{ width: '30%' }}>
                    Pricing Detail
                </Typography>
                {pricing && !isLoading ? <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ mb: 2, width: '70%' }}>
                    {selectedPricingLibrary?.libraryType === LibraryType.Custom ?
                        <>
                            <Button sx={{ marginRight: 2 }} variant="outlined" size="small" onClick={handlePricingDelete}>Delete</Button>
                            <Button sx={{ marginRight: 2 }} variant="outlined" size="small" onClick={() => setIsPricingModalOpen(true)}>Update</Button>
                        </>
                        : <></>}
                    <Typography variant="h6" color="text.secondary">
                        <Chip color={pricing.pricingState === PricingStateType.Validated ? 'success' : 'warning'} label={PricingStateValTypeDict[pricing.pricingState]} />
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        <Chip color={pricing.pricingType === PricingType.Analysis ? 'primary' : pricing.pricingType === PricingType.Proforma ? 'error' : 'info'} label={PricingTypeValDict[pricing.pricingType]} />
                    </Typography>
                </Stack> : <></>}
            </Stack>
            {!selectedPricingLibrary ? (
                <Typography color="text.secondary" textAlign="center">
                    Please select a pricing library!
                </Typography>
            ) : !selectedPricingID ? (
                <Typography color="text.secondary" textAlign="center">
                    Please select a pricing {selectedPricingLibrary?.libraryType === LibraryType.Custom ?
                        <>or <Button variant="outlined" size="small" onClick={() => setIsPricingModalOpen(true)}>Create Pricing</Button></>
                        : <></>}
                </Typography>
            ) : (isLoading === true || !pricing) ? (
                <CircularProgress />
            ) : (pricing && isLoading === false) ? (
                <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Code</TableCell>
                                <TableCell align="left">Definition</TableCell>
                                <TableCell align="center">Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <PricingRow row={pricing} />
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                    <Typography color="text.secondary" textAlign="center">
                        No data available.
                    </Typography>
                </Stack>
            )}
        </Paper>
    );
}
