import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import IconButton from '@mui/material/IconButton';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined'
import WorkOrderType from '../../../constants/WorkOrderType';
import { fileToBase64 } from '../../common/helpers/FileToBase64Helper';


export default function ImportTakeoffDialog({ importTakeoffIsOpen, setImportTakeoffIsOpen, handleImportTakeoffSuccess, projectID, workTypeID }) {
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();

    const handleImportTakeoffClose = () => {
        setFile(undefined);
        setIsLoading(false);
        setImportTakeoffIsOpen(false);
    };

    const handleSelectFile = (e) => {

        let files = e?.target?.files;
        let file = files && files.length > 0 ? files[0] : undefined;
        if (file) {
            setFile(file);
        }
    };

    const handleDownloadTemplate = async () => {
        var res = await approxAPIClient.fetchImportTakeoffRowsExcelTemplate(projectID, workTypeID);
        if (res.isSuccessful === true) {
            var a = document.createElement("a");
            a.href = "data:text/csv;base64," + res.data;
            a.download = "template.csv";
            a.click();
        } else {
            alert(res.error);
        }

    }

    const handleDownloadWorkOrderList = async () => {
        var res = await approxAPIClient.fetchNonCompletedUserWorkOrdersExcelTemplate(projectID, workTypeID, WorkOrderType.Takeoff);
        if (res.isSuccessful === true) {
            var a = document.createElement("a");
            a.href = "data:text/csv;base64," + res.data;
            a.download = "userWorkOrders.csv";
            a.click();
        } else {
            alert(res.error);
        }

    }

    return <Dialog
        open={importTakeoffIsOpen}
        onClose={handleImportTakeoffClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                setIsLoading(true);
                event.preventDefault();
                var reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = async () => {
                    const base64Data = await fileToBase64(reader.result);
                    const strProjectID = projectID.toString();
                    const strWorkTypeID = workTypeID.toString();
                    const input = {
                        base64Data: base64Data,
                        projectID: strProjectID,
                        workTypeID: strWorkTypeID
                    }

                    await approxAPIClient.importTakeoffWorkOrderRows(input).then((res) => {
                        if (res.isSuccessful === true) {
                            handleImportTakeoffSuccess();
                        } else {
                            alert(res.error);
                        }
                        setIsLoading(false);
                        handleImportTakeoffClose();
                    })
                };
            },
        }}
    >
        <DialogTitle>Import Takeoff</DialogTitle>
        {
            !isLoading ? <>
                <DialogContent>
                    <Button onClick={handleDownloadTemplate}>Download Template</Button>
                    <Button onClick={handleDownloadWorkOrderList}>Download Non Completed User Work Orders</Button>
                    <TextField
                        variant="standard"
                        fullWidth
                        type="text"
                        value={file?.name}
                        disabled
                        required
                        InputProps={{
                            endAdornment: (
                                <IconButton component="label">
                                    <FileUploadOutlined />
                                    <input
                                        styles={{ display: "none" }}
                                        type="file"
                                        hidden
                                        accept=".csv"
                                        onChange={(e) => handleSelectFile(e)}
                                        name="propertyInput"
                                    />
                                </IconButton>
                            ),
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleImportTakeoffClose}>Cancel</Button>
                    <Button type="submit" disabled={!file}>Import</Button>
                </DialogActions>


            </> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        }
    </Dialog>
}