import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { base64ToBytes } from '../../common/helpers/FileToBase64Helper';
import ApproxAPIClient from "../../../ApproxAPIClient";


export default function ProformaInvoiceViewer({ selectedProformaInvoice }) {
    const [pdfData, setPdfData] = useState(null);
    const defaultLayout = defaultLayoutPlugin();
    const approxAPIClient = ApproxAPIClient();
    useEffect(() => {
        async function fetchInvoice() {
            if (selectedProformaInvoice?.invoiceURL) {
                try {
                    const response = await approxAPIClient.downloadTenantFileAsBase64ByBlobID(selectedProformaInvoice.blobID);
                    if (!response.isSuccessful) {
                        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
                    }
                    const base64 = response.data;
                    const bytes = base64ToBytes(base64);
                    setPdfData(bytes);
                } catch (error) {
                    console.error(error);
                    setPdfData(null);
                }
            } else {
                setPdfData(null);
            }
        }

        fetchInvoice();
    }, [selectedProformaInvoice, approxAPIClient]);

    return (
        <Paper style={{ height: "100%", display: "flex", flexDirection: "column", overflow: 'hidden' }}>
            <Typography sx={{ padding: 2 }} color="text.secondary" textAlign="left">
                Proforma Invoice File
            </Typography>
            {pdfData ? (
                <Box style={{ height: '90vh', display: 'block', overflow: "hidden" }}>
                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={pdfData}
                            plugins={[defaultLayout]} // Include the default layout plugin
                        />
                    </Worker>
                </Box>
            ) : (
                <Typography
                    sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}
                    color="text.secondary"
                >
                    {selectedProformaInvoice ? "Loading PDF..." : "Please select an invoice!"}
                </Typography>
            )}
        </Paper>
    );
}
