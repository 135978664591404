import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ApproxAPIClient from '../../../ApproxAPIClient';
import DQBDataGrid from '../../common/components/DQBDataGrid';
import { PricingUnitType, PricingUnitTypeDict } from '../../../constants/PricingUnitType';

export default function PricingList({ selectedPricingLibrary, setSelectedPricing, selectedValidDate, pricingListRenderID, refreshLibraryList }) {

    const approxAPIClient = ApproxAPIClient();
    const pricingColumnInfo = [
        { name: "id", readableName: "ID", type: "String", isHidden: true },
        { name: 'code', readableName: 'Code', type: 'String' },
        { name: 'definition', readableName: 'Definition', type: 'String' },
        { name: 'pricingUnit', readableName: 'Pricing Unit', type: 'Enum', enumType: PricingUnitType, enumTypeDict: PricingUnitTypeDict },
        { name: 'unitPrice', readableName: 'Unit Price', type: 'Numeric' },
    ];

    const memoizedApiClientParams = useMemo(() => {
        return { pricingLibraryID: selectedPricingLibrary?.id, validFrom: selectedValidDate?.validFrom, validTo: selectedValidDate?.validTo };
    }, [selectedPricingLibrary?.id, selectedValidDate?.validFrom, selectedValidDate?.validTo]);

    return (
        <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                    Pricings
                </Typography>
            </Stack>
            {!selectedPricingLibrary ? <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                Please select a library!
            </Typography> : !selectedValidDate ? <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                Please select a date!
            </Typography> : <DQBDataGrid
                columns={pricingColumnInfo}
                apiClientMethod={approxAPIClient.queryPricingsByLibraryID}
                pageSize={5}
                apiClientParams={memoizedApiClientParams}
                handleRowSelect={setSelectedPricing}
                renderID={pricingListRenderID}
                refreshParentList={refreshLibraryList}
            />
            }
        </Paper>
    )
}