const PricingType = {
    None: 0,
    Analysis: 1,
    Proforma: 2,
    SingleLine: 3
}

const PricingTypeValDict = {
    1: "Analysis",
    2: "Proforma",
    3: "SingleLine",
}

export { PricingType, PricingTypeValDict };