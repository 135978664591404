import React, { useEffect, useState, useMemo } from 'react';
import {
    Modal,
    Box,
    Switch,
    TextField,
    Typography,
    Checkbox,
    ListItemText,
    Button,
    Stack,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    Dialog,
    DialogContent,
    FormGroup,
    FormControlLabel,
    DialogTitle
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { PricingUnitValTypeDict } from '../../../constants/PricingUnitType';
import { ProformaCalculationMethod, ProformaCalculationMethodValTypeDict } from '../../../constants/ProformaCalculationMethod';
import { PricingStateType } from '../../../constants/PricingStateType';
import { PricingType } from '../../../constants/PricingType'
import ApproxAPIClient from '../../../ApproxAPIClient';
import SelectPricingModal from '../SelectPricingModal';
import SelectProformaModal from '../SelectProformaModal';

export default function PricingModal({ open, setIsOpen, pricingData, pricingLibraryID, handleRefreshPricingList, handleRefreshPricing, refreshLibraryList, handleResetPricingSelect }) {
    const approxAPI = ApproxAPIClient();
    const initialPricing = useMemo(() => {
        return {
            id: null,
            code: '',
            definition: '',
            pricingUnit: '',
            description: '',
            contractorProfitPercentage: 0,
            startDate: '',
            endDate: '',
            items: [],
            proformaInvoicePrices: [],
            pricingType: PricingType.Analysis,
            pricingState: PricingStateType.Draft,
            proformaCalculationMethod: ProformaCalculationMethod.None
        }
    }, []);

    const [pricing, setPricing] = useState(initialPricing);
    const [isLoading, setIsLoading] = useState(false);
    const [selectPricingModalIsOpen, setSelectPricingModalIsOpen] = useState(false);
    const [itemSelectIndex, setItemSelectIndex] = useState(false);
    const [addPercentagePricingModalIsOpen, setAddPercentagePricingModalIsOpen] = useState(false);
    const [selectProformaModalIsOpen, setSelectProformaModalIsOpen] = useState(false);
    const [proformaPriceSelectIndex, setProformaPriceSelectIndex] = useState(false);

    const getPercentagePricing = (prc) => {
        const percentagePricingItems = [...(prc?.items?.filter((x) => x.percentagePricing !== null) ?? [])];
        if (percentagePricingItems.length === 0) {
            return null;
        }

        const relatedItemIDs = percentagePricingItems.map((x) => x.id);
        const definition = percentagePricingItems[0].percentagePricing.definition;
        const percentage = percentagePricingItems[0].percentagePricing.percentage;

        return {
            definition: definition,
            percentage: percentage,
            relatedItemIDs: [...relatedItemIDs]
        }
    }

    const [percentagePricing, setPercentagePricing] = useState();

    const handleSearchPricingButtonClick = (index) => {
        setSelectPricingModalIsOpen(true);
        setItemSelectIndex(index);
    }

    const handleSearchProformaInvoicePriceButtonClick = (index) => {
        setSelectProformaModalIsOpen(true);
        setProformaPriceSelectIndex(index);
    }

    const handlePricingStateChange = (event) => {
        setPricing({ ...pricing, "pricingState": event.target.checked ? PricingStateType.Validated : PricingStateType.Draft });
    }

    const handlePricingTypeChange = (event) => {
        if (pricingData && pricingData.pricingState === PricingStateType.Validated) {
            alert("Pricing State can not be changed for Validated Pricings!");
            return;
        }

        if (pricingData && (pricingData.items ?? []).length > 0) {
            alert("To change pricing type, ensure there is no existing items!");
            return;
        }

        const pricingType = event.target.checked ? PricingType.Proforma : PricingType.Analysis;
        const proformaCalculationMethod = pricingType === PricingType.Proforma ? ProformaCalculationMethod.Avg : ProformaCalculationMethod.None;

        setPricing({
            ...pricing,
            pricingType: pricingType,
            items: [],
            proformaInvoicePrices: [],
            pricingState: PricingStateType.Draft,
            proformaCalculationMethod: proformaCalculationMethod
        });

        setPercentagePricing(undefined);
    }

    useEffect(() => {
        if (pricingData) {
            const pp = getPercentagePricing(pricingData);
            setPercentagePricing({ ...pp });
        } else {
            setPercentagePricing(null);
        }
    }, [pricingData])

    useEffect(() => {
        const prcState = pricingData ? pricingData : initialPricing;
        setPricing({ ...prcState })
    }, [pricingData, initialPricing])

    const handlePricingSelect = (selectedPricing) => {
        handleItemPricingChange(selectedPricing);
    }

    const handleProformaPriceSelect = (selectedProformaInvoice, selectedProformaInvoicePrice) => {
        handleProformaPriceChange(selectedProformaInvoice, selectedProformaInvoicePrice);
    }

    const handleInputChange = (field, value) => {
        setPricing({ ...pricing, [field]: value });
    };

    const setPricingToInitialState = () => {
        const initPricing = pricingData ? pricingData : initialPricing;
        setPricing({ ...initPricing });
        const initPercentagePricing = pricingData ? getPercentagePricing(pricingData) : null;
        setPercentagePricing(initPercentagePricing ? { ...initPercentagePricing } : null);
    }

    const handleAddItem = () => {
        setPricing({
            ...pricing,
            items: [...pricing.items, { id: null, code: '', definition: '', pricingUnit: '', amount: 0, unitPrice: 0 }],
        });
    };

    const handleAddProformaPrice = () => {
        setPricing({
            ...pricing,
            proformaInvoicePrices: [...pricing.proformaInvoicePrices, { proformaInvoiceID: null, proformaInvoicePriceID: null, code: '', companyName: '', definition: '', unit: '', price: 0 }],
        });
    }

    const handleProformaPriceDelete = (indexToDelete) => {
        let newItems = [...pricing.proformaInvoicePrices];
        newItems = [...newItems.filter((x, index) => indexToDelete !== index)];
        setPricing({ ...pricing, proformaInvoicePrices: [...newItems] });
    }

    const handleDeleteItem = (indexToDelete) => {
        let newItems = [...pricing.items];
        newItems = [...newItems.filter((x, index) => indexToDelete !== index)];
        const newItemIDs = newItems.map((x) => x.id);
        const newPercentagePringRelatedItemIDs = (percentagePricing?.relatedItemIDs ?? []).filter(x => newItemIDs.includes(x));
        const newPercentagePricing = { ...percentagePricing, relatedItemIDs: newPercentagePringRelatedItemIDs };
        setPercentagePricing(newPercentagePringRelatedItemIDs.length > 0 ? { ...newPercentagePricing } : null);
        setPricing({ ...pricing, items: [...newItems] });
    }

    const handleItemPricingChange = (selected) => {
        const updatedItems = [...pricing.items];
        updatedItems[itemSelectIndex]['code'] = selected.code;
        updatedItems[itemSelectIndex]['definition'] = selected.definition;
        updatedItems[itemSelectIndex]['pricingUnit'] = selected.pricingUnit;
        updatedItems[itemSelectIndex]['unitPrice'] = selected.unitPrice / (1 + selected.contractorProfitPercentage);
        updatedItems[itemSelectIndex]['id'] = selected.id;
        setPricing({ ...pricing, items: updatedItems });
        setItemSelectIndex(undefined);
    };

    const handleProformaPriceChange = (selectedProformaInvoice, selectedProformaInvoicePrice) => {
        const updatedItems = [...pricing.proformaInvoicePrices];
        updatedItems[proformaPriceSelectIndex]['code'] = selectedProformaInvoicePrice.code;
        updatedItems[proformaPriceSelectIndex]['companyName'] = selectedProformaInvoice.companyName;
        updatedItems[proformaPriceSelectIndex]['definition'] = selectedProformaInvoicePrice.definition;
        updatedItems[proformaPriceSelectIndex]['unit'] = selectedProformaInvoicePrice.pricingUnit;
        updatedItems[proformaPriceSelectIndex]['price'] = selectedProformaInvoicePrice.price;
        updatedItems[proformaPriceSelectIndex]['proformaInvoiceID'] = selectedProformaInvoice.id;
        updatedItems[proformaPriceSelectIndex]['proformaInvoicePriceID'] = selectedProformaInvoicePrice.id;
        setPricing({ ...pricing, proformaInvoicePrices: updatedItems });
        setProformaPriceSelectIndex(undefined);
    };

    const handleItemAmountChange = (index, value) => {
        const updatedItems = [...pricing.items];
        let item = updatedItems[index];
        item["amount"] = value;
        if (item.percentagePricing) {
            item.percentagePricing.price = item.amount * item.unitPrice * item.percentagePricing.percentage
        }
        setPricing({ ...pricing, items: updatedItems });
    };

    const updateItemsPercentagePricing = (newPercentagePricing) => {
        let pricingItems = [...(pricing?.items ?? [])];
        const newPercentagePricingItemIDs = newPercentagePricing?.relatedItemIDs ?? [];

        pricingItems.forEach(item => {
            if (newPercentagePricingItemIDs.includes(item.id)) {
                item.percentagePricing = {
                    definition: newPercentagePricing.definition,
                    percentage: newPercentagePricing.percentage,
                    price: item.amount * item.unitPrice * newPercentagePricing.percentage
                };
            } else {
                item.percentagePricing = null;
            }
        });

        setPricing({ ...pricing, items: pricingItems });
    }

    const handlePercentagePricingValuesChange = (event, field) => {
        const newPercentagePricing = { ...(percentagePricing ?? {}), [field]: event.target.value };
        setPercentagePricing({ ...newPercentagePricing })
        updateItemsPercentagePricing({ ...newPercentagePricing });
    }

    const handlePercentagePricingIDsChange = (event) => {
        const {
            target: { value },
        } = event;

        const relatedItemIDs = typeof value === 'string' ? value.split(',') : value;
        const newPercentagePricing = { ...(percentagePricing ?? {}), relatedItemIDs: [...relatedItemIDs] };
        setPercentagePricing({ ...newPercentagePricing });
        updateItemsPercentagePricing({ ...newPercentagePricing });
    };

    const handleClearPercentagePricing = () => {
        setPercentagePricing(null);
        updateItemsPercentagePricing(null);
    }

    const handleProformaCalculationMethodChange = (e) => {
        setPricing({ ...pricing, proformaCalculationMethod: e.target.value });
    }

    const handleSave = () => {
        const input = {
            id: pricing.id,
            pricingLibraryID: pricingLibraryID,
            code: pricing.code,
            definition: pricing.definition,
            unit: pricing.pricingUnit,
            pricingType: pricing.pricingType,
            pricingState: pricing.pricingState,
            proformaCalculationMethod: pricing.proformaCalculationMethod,
            items: pricing.items.map((item) => ({
                pricingID: item.id,
                quantity: item.amount
            })),
            proformaInvoicePrices: pricing.proformaInvoicePrices.map((item) => ({
                proformaInvoiceID: item.proformaInvoiceID,
                proformaInvoicePriceID: item.proformaInvoicePriceID
            })),
            percentagePricing: (percentagePricing?.relatedItemIDs ?? []).length > 0 ? percentagePricing : null,
            contractorProfitPercentage: pricing.contractorProfitPercentage,
            description: pricing.description,
            startDate: pricing.startDate,
            endDate: pricing.endDate
        };

        setIsLoading(true);
        const endpoint = pricingData ? approxAPI.updateCustomPricing : approxAPI.createCustomPricing;
        endpoint(input).then((res) => {
            if (res.isSuccessful !== true) {
                alert(res.error);
            } else {
                handleRefreshPricingList();
                if (pricingData) {
                    handleRefreshPricing();
                } else {
                    refreshLibraryList();
                    handleResetPricingSelect();
                }

                setPricingToInitialState();
                setIsOpen(false);
            }
            setIsLoading(false);
        })
    };

    const handleCancel = () => {
        setPricingToInitialState();
        setIsOpen(false);
    };

    return (isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
        </Box>) :
        <>
            <Modal open={open} onClose={handleCancel}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: '80%',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {pricingData ? 'Update Pricing' : 'Create Pricing'}
                    </Typography>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2, width: '100%' }}>
                            <TextField
                                label="Code"
                                value={pricing.code}
                                onChange={(e) => handleInputChange('code', e.target.value)}
                                sx={{ width: '20%' }}
                            />
                            <TextField
                                label="Definition"
                                value={pricing.definition}
                                onChange={(e) => handleInputChange('definition', e.target.value)}
                                sx={{ width: '70%' }}
                            />
                            <Select
                                label="Pricing Unit"
                                value={pricing.pricingUnit}
                                onChange={(e) => handleInputChange('pricingUnit', e.target.value)}
                                sx={{ width: '10%' }}
                            >
                                {Object.entries(PricingUnitValTypeDict).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                        <TextField
                            label="Description"
                            value={pricing.description}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                        />
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2, width: '100%' }}>
                            <TextField
                                label="Contractor Profit Percentage"
                                type="number"
                                value={pricing.contractorProfitPercentage}
                                onChange={(e) => handleInputChange('contractorProfitPercentage', parseFloat(e.target.value))}
                                fullWidth
                            />
                            <TextField
                                label="Valid From"
                                type="date"
                                value={pricing.startDate}
                                onChange={(e) => handleInputChange('startDate', e.target.value)}
                                fullWidth
                            />
                            <TextField
                                label="Valid To"
                                type="date"
                                value={pricing.endDate}
                                onChange={(e) => handleInputChange('endDate', e.target.value)}
                                fullWidth
                            />
                        </Stack>

                    </Stack>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            checked={pricing?.pricingType === PricingType.Proforma}
                            onChange={handlePricingTypeChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Is Proforma Pricing?" />
                    </FormGroup>
                    {pricing?.pricingType === PricingType.Proforma ? <>
                        <InputLabel id="Calculation-Method-label">Calculation Method</InputLabel>
                        <Select
                            value={pricing.proformaCalculationMethod}
                            onChange={handleProformaCalculationMethodChange}
                            fullWidth
                            labelId="Calculation-Method-label"
                        >
                            {Object.entries(ProformaCalculationMethodValTypeDict).map(([key, value]) => (
                                <MenuItem key={key} value={Number(key)}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                            Proforma Invoice Prices
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Company Name</TableCell>
                                        <TableCell>Definition</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={`totalprice#${pricing.id}`}>
                                        <TableCell align='right' colSpan={5}>Price: </TableCell>
                                        <TableCell colSpan={2}>
                                            {(() => {
                                                let itemsSum = 0;
                                                const sorted = [...pricing.proformaInvoicePrices].sort((a, b) => ((a.price ?? 0) - (b.price ?? 0)));
                                                if (sorted.length > 0) {
                                                    switch (pricing?.proformaCalculationMethod) {
                                                        case ProformaCalculationMethod.Avg:
                                                            let total = 0;
                                                            sorted.forEach((x) => {
                                                                total += x.price ?? 0;
                                                            })
                                                            itemsSum = total / sorted.length;
                                                            break;
                                                        case ProformaCalculationMethod.Mid:
                                                            const midIndex = ((sorted.length % 2 === 0 ? sorted.length / 2 : (sorted.length + 1) / 2) - 1);
                                                            itemsSum = sorted[midIndex].price ?? 0;
                                                            break;
                                                        case ProformaCalculationMethod.Min:
                                                            itemsSum = sorted[0].price ?? 0;
                                                            break;
                                                        case ProformaCalculationMethod.Max:
                                                            itemsSum = sorted[sorted.length - 1].price ?? 0;
                                                            break;

                                                        default:
                                                            itemsSum = 0;
                                                            break;
                                                    }
                                                }

                                                const subTotal = itemsSum;
                                                const contractorProfit = subTotal * pricing?.contractorProfitPercentage ?? 0;

                                                return ((subTotal + contractorProfit) || 0).toFixed(2);
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                    {pricing.proformaInvoicePrices?.map((item, index) => (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <IconButton onClick={() => handleSearchProformaInvoicePriceButtonClick(index)}>
                                                        <ManageSearchIcon />
                                                    </IconButton>
                                                    <TextField
                                                        disabled
                                                        value={item.code ?? ''}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        value={item.companyName ?? ''}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        value={item.definition ?? ''}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        disabled
                                                        value={item.unit ?? ''}
                                                    >
                                                        {Object.entries(PricingUnitValTypeDict).map(([key, value]) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        type="number"
                                                        value={item.price}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Button onClick={() => handleProformaPriceDelete(index)}>Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button onClick={handleAddProformaPrice} sx={{ mt: 2 }}>
                            Add Proforma Price
                        </Button>
                    </>
                        : <></>}
                    {pricing?.pricingType === PricingType.Analysis ? <>
                        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                                Percentage Pricing
                            </Typography>
                            <Button variant="outlined" onClick={() => setAddPercentagePricingModalIsOpen(true)} disabled={!pricing?.items || pricing.items.length === 0}>
                                Change
                            </Button>
                            <Button variant="outlined" onClick={handleClearPercentagePricing} disabled={!percentagePricing}>
                                Clear
                            </Button>
                        </Stack>
                        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                            Child Items
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Definition</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Unit Price</TableCell>
                                        <TableCell>Line Total</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={`totalprice#${pricing.id}`}>
                                        <TableCell align='right' colSpan={5}>Total Price: </TableCell>
                                        <TableCell colSpan={2}>
                                            {(() => {
                                                const relatedItems = (pricing?.items ?? []).filter((x) => x.percentagePricing !== null);
                                                let percentagePricingSum = 0;
                                                relatedItems.forEach((item) => {
                                                    percentagePricingSum += (item.amount * (item.unitPrice ?? 0)) * (item?.percentagePricing?.percentage ?? 0);
                                                });

                                                let itemsSum = 0;
                                                (pricing?.items ?? []).forEach((item) => {
                                                    itemsSum += (item.amount * (item.unitPrice ?? 0));
                                                });

                                                const subTotal = itemsSum + percentagePricingSum;
                                                const contractorProfit = subTotal * pricing?.contractorProfitPercentage ?? 0;

                                                return (subTotal + contractorProfit) || 0;
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                    {pricing.items?.map((item, index) => (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <IconButton onClick={() => handleSearchPricingButtonClick(index)}>
                                                        <ManageSearchIcon />
                                                    </IconButton>
                                                    <TextField
                                                        disabled
                                                        value={item.code ?? ''}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        value={item.definition ?? ''}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        disabled
                                                        value={item.pricingUnit ?? ''}
                                                    >
                                                        {Object.entries(PricingUnitValTypeDict).map(([key, value]) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={item.amount}
                                                        onChange={(e) => handleItemAmountChange(index, parseFloat(e.target.value))}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        disabled
                                                        type="number"
                                                        value={item.unitPrice}
                                                    />
                                                </TableCell>
                                                <TableCell>{(item.amount * item.unitPrice ?? 0).toFixed(2)}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => handleDeleteItem(index)}>Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                            {item.percentagePricing ? <TableRow key={`pp${index}`}>
                                                <TableCell align='right' colSpan={5}>
                                                    {`${item.percentagePricing.definition} (${item.percentagePricing.percentage})`}
                                                </TableCell>
                                                <TableCell colSpan={1}>
                                                    {item.percentagePricing.price.toFixed(2)}
                                                </TableCell>
                                            </TableRow> : <></>}
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button onClick={handleAddItem} sx={{ mt: 2 }}>
                            Add Item
                        </Button>
                    </>
                        : <></>}


                    <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>

                        <FormGroup>
                            <FormControlLabel control={<Switch
                                checked={pricing?.pricingState === PricingStateType.Validated}
                                onChange={handlePricingStateChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Validate Pricing?" />
                        </FormGroup>
                    </Stack>
                </Box>
            </Modal>
            <SelectPricingModal selectPricingModalKey={pricingData?.id ?? "SelectPricingModal"} open={selectPricingModalIsOpen} setIsOpen={setSelectPricingModalIsOpen} handlePricingSelect={handlePricingSelect} />
            <SelectProformaModal selectProformaModalKey={pricingData?.id ?? "SelectProformaModal"} open={selectProformaModalIsOpen} setIsOpen={setSelectProformaModalIsOpen} handleProformaPriceSelect={handleProformaPriceSelect} />
            <Dialog open={addPercentagePricingModalIsOpen} onClose={() => setAddPercentagePricingModalIsOpen(false)} maxWidth='md' fullWidth>
                <DialogTitle>
                    Create Percentage Pricing
                </DialogTitle>
                <DialogContent >
                    <TextField
                        label="Definition"
                        value={percentagePricing?.definition}
                        onChange={(e) => handlePercentagePricingValuesChange(e, "definition")}
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                    />
                    <TextField
                        label="Percentage"
                        type="number"
                        value={percentagePricing?.percentage ?? 0}
                        onChange={(e) => handlePercentagePricingValuesChange(e, "percentage")}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                    />
                    <Select
                        multiple
                        fullWidth
                        value={percentagePricing?.relatedItemIDs ?? []}
                        onChange={handlePercentagePricingIDsChange}
                        renderValue={(all) => all.map((itemId) =>
                            pricing.items?.find((item) => item.id === itemId)?.code).join(",") ?? "-"}
                    >
                        {pricing?.items?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={(percentagePricing?.relatedItemIDs ?? []).includes(item.id)} />
                                <ListItemText primary={item?.code} />
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
            </Dialog>
        </>
    );
}